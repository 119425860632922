import Vue from 'vue'
import Vuex from 'vuex'
import Helper from './assets/js/helper';
import LinksPage from './assets/js/LinksPage';
import router from './router';
import Product from './assets/js/produto';
import PixelAnalytics from './assets/js/PixelAnalytics';
import Store from './store';

import ImpactStore from './store/Impact'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        impact: ImpactStore
    },
    state: {
        user: {},
        userFather: {},
        plano: {},
        taxa: {},
        tool: {},
        bumpsell: [],
        bumpsellPrice: 0.00,
        planCountryPrice: {},
        paymentMethodSelected: null,
        paymentMethodEnabled: {
            myCards: false,
            creditCard: false,
            sepa: false,
            paypal: false,
            pagSeguro: false,
            boleto: false,
            pix: false
        },
        myCardSelectedPayment: {}, //Cartão selecionado para pagamento quando ja existe cartão vinculado (Ex: para pagamento com one-click)
        boleto: {
            barCode: '',
            barCodeImage: '',
            qrCode: '',
            dueDate: '',
            downloadPdf: ''
        },
        pix: {
            qrCode: '',
            qrCodeUrl: ''
        },
        localeUser: {
            isEurope: false,
            isDutch: false,
            isRegionCanarias: false,
            isFrenchGuiana: false, //Guiana Francesa
            isMalaysia: false,
            isCanada: false,
            isUnitedKingdom: false,
            isFrenchPolynesia: false,
            isEmptyVat: false,
            country: null,
            ip: null,
            region: null
        },
        vatFee: {
            isValid: false,
            percentage: 0,
            amount: 0.00,
            amountFirstMonth: 0.00,
            amountWithoutCoupon: 0.00,
            vatNumberCompleted: '',
            vatNumber: null,
            vatCountry: null,
            vatCompanyName: null,
            vatCompanyAddress: null
        },
        countryEuroDefined: null,
        translate: {},
        fatura: {},
        invoicePlanToActive: null,
        dataNote: {}, //Dados onde é salvo na coluna nota nas faturas do office
        totalPossibleFraud: 0,
        fullScreenLoading: {
            status: false,
            text: ''
        },
        endpointOffice: {
            IDENTIFY_LOGGED_IN_USER: '/br/office/login-status?check-team-account=1&get-current-plan=1&cc=1',
            GET_CURRENT_PLAN_USER: '/br/office/get-user-has-trial-active-invoice', //passar o id do usuario
            PEGAR_PLANO_OU_TAXA: 'br/office/plan-or-fee',
            CRIAR_INVOICE: '/br/office/invoice/create',
            CREATE_INVOICE_DOMAIN: 'br/office/create-invoice-domain',
            CREATE_INVOICE_STORAGE: 'br/office/create-invoice-extra-storage',
            CREATE_INVOICE_DOMAIN_EXTRA_CONNECTION: 'br/office/create-invoice-extra-connection',
            UPDATE_INVOICE: '/br/office/update-invoice', //Precisa passar o parametro /id_fatura
            FRAUDE: '/br/identificacao-fraude/builderall_gateway',
            LOCALE_BY_IP: '/br/office/search-locale-by-ip',
            GET_CUSTOM_THANK_YOU_PAGE: '/br/office/get-custom-thank-you-page',
            CHECK_COUPON: '/br/office/check-coupon',
            VALID_INVOICE: 'br/office/invoices/confirm-user', //Precisa de 2 parametros apos o confirm-user. 1- ID Fatura. 2 - ID User
            GET_PIXEL_FUNNEL: '/br/office/get-funnel-complements',
            GET_PARENT_PIXEL: 'us/office/pixel-events/get-parent-pixels',
            INVOICE_PAY: 'office/invoice-pay',
            LOGIN: 'us/office/login',
            ACCOUNT_INFO: 'us/office/get-account-infos', //Precisa passar o parametro ?id=99999
            PAGSEGURO: 'br/office/handle-pagseguro',
            RECOVER_PASSWORD: 'us/office/forgot-password', // precisa passar o parametro email. ?email=teste@gmail.com
            SPONSOR_RECEIVER_IS_VALID: 'us/office/plan-or-fee/sponsor-receiver-is-valid',
            REGISTER_DOMAIN: 'office/register-domain-price',
            RENEW_DOMAIN: 'office/is-domain-renew-available',
            PRICE_STORAGE: 'office/extra-storage-price',
            PRICE_DOMAIN_EXTRA_CONNECTION: 'office/extra-connection-price',
            SUBSIDIARIES: '/br/office/get-subsidiaries', //Retorna Subsidiaries do office
            UPDATE_USER_CONFIG: '/br/office/user/config',
            COUNTRY_PRICE: '/us/office/api/country-prices' //id da subsidiaria como parametro opcional
        },
        endpointGateway: {
            taxa: '/pay/single',
            paymentAdyen: '/pay/recurrent',
            futurePayment: '/pay/recurrent/future-payment',
            boleto: '/pay/boleto',
            sepa: '/pay/sepa',
            paymentStripe: '/stripe/pay',
            setupIntentStripe: '/stripe/setup-intent',
            paymentPagarme: '/pagarme/pay',
            paymentPagarmeBoleto: '/pagarme/pay-boleto',
            paymentPagarmePix: '/pagarme/pay-pix',
            vatValidate: '/vat/validate',
            vatGetFee: '/vat/get-fee',
            vatGetFees: 'vat/get-fees',
            userTokensCreditCard: '/user/tokens-credit-card',
            debitPaymentTokenCard: '/debit-payment-token-card'
        },
        adyenKeys: {
            publicKeyTest: '10001|AEC7A73E57936641AF8692A892DD05EA82F3DAC2D648A9589FE8788B2F67C855163A39D0810741D836F2DA7E62772980B9A9A2D83F5DA0453A408F1AF5438FD126D07B92659E8711D498D4136ED0056B890B0F98A3C4C6ABF5C97305208EA79612A59C3D59B6D637487AD63FD96951BA05D2E3E329823BE2774A16468ED12BCFE9156841D475D8861065E8F3ED91DB51450F21136CF4213635B77D9E11C90DA95443376A8E8742053B097E13B3A10FBCE8DC5ACFDCD7180AB18C812B3308E8BF87F72FB3FDBE20AE6F7732ABFFED2D5DCDED499BA11ABA774C2B587AD240D0E860F5F47BF081CA468559B2B7835B26A85364F1E27E1D9C7A0CCF37E91AF481AD',
            publicKeyLive: '10001|CFEFB9B724628E0041D093146F2FBB4184BE7EAD440F84ED24CF9F141E6214E68DCED5665B154E90B8D1B2FCA832C62357C9E87DCF5C765B7256B3234C5873C79BE0A250E4E8ECCDDC5BD6E63033B0F37D17C4EE4BBAB3083F0FDBFFC895E0A74EF3295CFCCEF4976FF91A4AF72BBCACBBF0F64AD71AD1A9DECC0A22DA326D6231E5FFD180974AF815DA98CC6E3892397FFBC746542038A693AA5D742C7DB2B00E710A94735CA69F2A611723ECEB11804AB425756D9E9305D63267233B3ABA6B81E14967C83E7EA4B114A8FB4EACDB033C0F0BEE571E887958640F42C8FFE93BD3FA819ABF6DD1F14FAF16598600AB8FA852E43ACDE2323C890540E0DB39D4D9'
        },
        coupon: {},
        fakeAmountPlanOrFee: null,
        isInvoiceUrlValid: null,
        isPaymentOnlyPaypal: false,
        isFirstPaymentUpLine: false,
        isUserFatherToReceiveByGateway: false,
        isPaymentPlanWordpressCloud: false,
        isPaymentCheetahTemplate: false,
        cheetahTemplateDetails: null,
        isPlan: '',
        isBuyTool: false,
        isBuyPlanOrFee: false,
        isPaymentDomain: false,
        isPaymentDomainRegistry: false,
        isPaymentDomainExtraConnection: false,
        isPaymentStorage: false,
        isPaymentCountryPrice: false,
        isChargedAmount: false,
        isPauseAccount: false,
        stepAction: false,
        bgResponse: {
            payment: {},
            future: {}
        },
        devMode: false,
        tokenCheckout: null
    },
    getters: {
        ajustePlano: (state, getters) => {
            if(!state.plano.hasOwnProperty('titulo')) return false;

            let moeda = '';
            let valor = parseFloat(state.plano.total_real).toFixed(2);
            let isToolUrl = state.plano.slug.search('-tool') !== -1 ? true : false;
            let isBuyStorage = state.plano.hasOwnProperty('storage_kbs') ? true : false;

            switch(state.plano.moeda){
                case 'USD':
                    moeda = '$';
                break;
                case 'BRL':
                    moeda = 'R$';
                    valor = valor.replace('.', ',');
                break;
                case 'EUR':
                    moeda = '€';
                break;
                case 'CZK':
                    moeda = 'Kč';    
                break;
                case 'THB':
                    moeda = '฿';
                break;
                default:
                    moeda = state.isPaymentCountryPrice || state.isChargedAmount || state.isPauseAccount ? state.plano.symbol : '$';
                break;
            }

            if(!state.isChargedAmount && state.isPaymentCountryPrice && ['funnel-club-plan', 'wp-funnel-club-plan', 'special-funnel-club-wp'].includes(state.plano.slug)){
                state.plano.additional_value_first_month = state.planCountryPrice.amountFirstMonth;
            }

            let amountFirstMonth = 0;
            if(parseFloat(state.plano.additional_value_first_month) > 0){
                    amountFirstMonth = state.isPaymentCountryPrice
                        ? parseFloat(state.plano.additional_value_first_month).toFixed(2)
                        : parseFloat(parseFloat(state.plano.additional_value_first_month) + parseFloat(valor.replace(',', '.'))).toFixed(2);
            }

            let amountCoupon = null;
            let amountCouponDiscount = null;
            if(Object.keys(state.coupon).length >= 1){
                amountCoupon = parseFloat(Helper.calcDiscount(
                    parseFloat(state.plano.additional_value_first_month) > 0 ? amountFirstMonth : valor.replace(',', '.'),
                    state.coupon.value,
                    state.coupon.type
                )).toFixed(2);

                amountCouponDiscount = parseFloat(valor.replace(',', '.') - amountCoupon).toFixed(2);
            }
            
            return {
                id: state.plano.id,
                title: state.plano.titulo,
                slug: state.plano.slug,
                image: state.plano.hasOwnProperty('logo') ? state.plano.logo : require('./assets/no-image.png'),
                currencySymbol: moeda,
                currency: state.plano.moeda,
                isTheCurrency: {
                    usd: state.plano.moeda == 'USD' ? true : false,
                    eur: state.plano.moeda == 'EUR' ? true : false,
                    brl: state.plano.moeda == 'BRL' ? true : false
                },
                amount: valor.replace(',', '.'),
                storage_kbs: state.plano.hasOwnProperty('storage_kbs') ? state.plano.storage_kbs : null,
                domain_extra_quantity: state.plano.hasOwnProperty('domain_extra_quantity') ? state.plano.domain_extra_quantity : null,
                interval: Helper.periodoRecorrente(state.plano.periodo),
                period: state.plano.periodo == 'MENSAL' ? getters.translateWord('Mensal') : getters.translateWord('Anual'),
                periodSymbol: state.plano.periodo == 'MENSAL' ? getters.translateWord('me') : getters.translateWord('an'),
                isBuyMonthly: state.plano.periodo == 'MENSAL' ? true : false,
                isBuyAnnual: state.plano.periodo == 'ANUAL' ? true : false,
                redirect: state.plano.url_after_payment,
                amountCoupon: amountCoupon,
                amountCouponDiscount: amountCouponDiscount,
                fakeAmount: state.plano.fake_amount,
                description: state.plano.descricao,
                tools: Helper.filterToolsList(state.plano.ferramentas),
                ignoreListTools: isToolUrl || isBuyStorage,
                resources: state.plano.front_resource_items,
                first_payment_to_upline: state.plano.first_payment_to_upline == '0' ? false : true,
                payment_gateways: state.plano.hasOwnProperty('payment_gateways') && state.plano.payment_gateways.length > 0 
                    ? state.plano.payment_gateways.map(gateway => {
                        if(gateway == 1) return 'paypal';
                        if(gateway == 4) return 'pagSeguro';
                    }) 
                    : [],
                isThirdParty: state.plano.is_a_third_party_product == 1 ? true : false,
                isTrialDays: parseInt(state.plano.trial_days) > 0 ? true : false,
                trialDays: parseInt(state.plano.trial_days) > 0 ? parseInt(state.plano.trial_days) : false,
                trialDaysAmount: parseInt(state.plano.trial_days) > 0 ? parseFloat(state.plano.trial_valor).toFixed(2) : false,
                isAmountFirstMonth: parseFloat(state.plano.additional_value_first_month) > 0 ? true : false,
                amountFirstMonth: amountFirstMonth,
                webhooks: state.plano.hasOwnProperty('webhooks') && state.plano.webhooks != '' 
                    ? JSON.parse(state.plano.webhooks)
                    : [Helper.getEnvs().webhookDefaultPlan]
            }
        },
        ajusteTaxa: (state, getters) => {
            if(!state.taxa.hasOwnProperty('name')) return false;
            
            let moeda = '';
            let valor = parseFloat(state.taxa.value).toFixed(2);

            switch(state.taxa.currency){
                case 'BRL':
                    moeda = 'R$';
                    valor = valor.replace('.', ',');
                    break;
                case 'EUR':
                    moeda = '€';
                    break;
                case 'CZK':
                    moeda = 'Kč';
                    break;
                case 'THB':
                    moeda = '฿';
                    break;
                default:
                    moeda = '$';
                    break;
            }

            let amountCoupon = null;
            if(Object.keys(state.coupon).length >= 1){
                amountCoupon = parseFloat(Helper.formatValueDiscount(valor, state.coupon.value, state.coupon.type)).toFixed(2);
            }
            
            let amountFixed = valor.replace(',', '.');
            if(state.bumpsell.length >= 1){
                valor = parseFloat(parseFloat(valor) + parseFloat(state.bumpsellPrice)).toFixed(2);
            }

            return {
                id: state.taxa.id,
                title: state.taxa.name,
                slug: state.taxa.slug,
                image: state.taxa.hasOwnProperty('logo') ? state.taxa.logo : require('./assets/no-image.png'),
                currencySymbol: moeda,
                currency: state.taxa.currency,
                isTheCurrency: {
                    usd: state.taxa.moeda == 'USD' ? true : false,
                    eur: state.taxa.moeda == 'EUR' ? true : false,
                    brl: state.taxa.moeda == 'BRL' ? true : false
                },
                amount: valor.replace(',', '.'),
                amountFixed: amountFixed,
                domain: state.taxa.hasOwnProperty('domain') ? state.taxa.domain : null,
                domainPeriod: state.taxa.hasOwnProperty('domain') ? state.taxa.period : null,
                domainTime: state.taxa.hasOwnProperty('domain') ? state.taxa.time : null,
                domainProtection: state.taxa.hasOwnProperty('domainProtection') ? state.taxa.domainProtection : null,
                redirect: state.taxa.url_after_payment,
                plan_to_active: {
                    id_plan_to_activate: state.taxa.plan_to_active != undefined ? state.taxa.id_plan_to_activate : null,
                    amount: state.taxa.plan_to_active != undefined ? parseFloat(state.taxa.plan_to_active.total_real).toFixed(2) : null,
                    slug: state.taxa.plan_to_active != undefined ? state.taxa.plan_to_active.slug : null,
                    title: state.taxa.plan_to_active != undefined ? state.taxa.plan_to_active.titulo : null,
                    interval: state.taxa.plan_to_active != undefined ? Helper.periodoRecorrente(state.taxa.plan_to_active.periodo) : null,
                    period: state.taxa.plan_to_active != undefined ? 'MONTH' : null,
                    periodSymbol: state.taxa.plan_to_active != undefined && state.taxa.plan_to_active.periodo != undefined && state.taxa.plan_to_active.periodo == 'MENSAL' ? 'mo' : 'an',
                    currency: state.taxa.plan_to_active != undefined ? state.taxa.plan_to_active.moeda : null,
                    webhooks: state.taxa.plan_to_active != undefined && state.taxa.plan_to_active.hasOwnProperty('webhooks') && state.taxa.plan_to_active.webhooks != ''
                        ? JSON.parse(state.taxa.plan_to_active.webhooks)
                        : [Helper.getEnvs().webhookDefaultFee]
                },
                amountCoupon: amountCoupon,
                fakeAmount: state.taxa.fake_amount,
                description: state.taxa.description.replaceAll('\n', '<br>'),
                tools: state.taxa.tools,
                periodSymbol: state.isPaymentDomain || state.isPaymentDomainExtraConnection ? 'an' : '',
                resources: state.taxa.front_resource_items,
                first_payment_to_upline: state.taxa.payment_to_upline == '0' ? false : true,
                payment_gateways: state.taxa.hasOwnProperty('payment_gateways') && state.taxa.payment_gateways.length > 0
                    ? state.taxa.payment_gateways.map(gateway => {
                        if(gateway == 1) return 'paypal';
                        if(gateway == 4) return 'pagSeguro';
                    })
                    : [],
                webhooks: state.taxa.hasOwnProperty('webhooks') && state.taxa.webhooks != ''
                    ? JSON.parse(state.taxa.webhooks)
                    : [Helper.getEnvs().webhookDefaultFee]
            }
        },
        adjustTool: (state, getters) => {
            let moeda = '';

            switch(state.tool.moeda){
                case 'BRL':
                    moeda = 'R$';
                    break;
                case 'EUR':
                    moeda = '€';
                    break;
                case 'CZK':
                    moeda = 'Kč';
                    break;
                case 'THB':
                    moeda = '฿';
                    break;
                default:
                    moeda = '$';
                    break;
            }

            return {
                id: state.tool.id,
                title: state.tool.titulo,
                slug: state.tool.slug,
                image: state.tool.hasOwnProperty('logo') ? state.tool.logo : require('./assets/no-image.png'),
                currencySymbol: moeda,
                currency: state.tool.moeda,
                isTheCurrency: {
                    usd: state.tool.moeda == 'USD' ? true : false,
                    eur: state.tool.moeda == 'EUR' ? true : false,
                    brl: state.tool.moeda == 'BRL' ? true : false
                },
                amount: state.tool.amount,
                interval: Helper.periodoRecorrente(state.tool.periodo),
                period: 'MONTH',
                periodSymbol: state.plano.periodo == 'MENSAL' ? 'mo' : 'an',
                redirect: state.tool.url_after_payment,
                fakeAmount: state.tool.fake_amount,
                description: state.tool.descricao,
                suggestions: state.tool.suggestions,
                payment_gateways: state.tool.hasOwnProperty('payment_gateways') && state.tool.payment_gateways.length > 0
                    ? state.tool.payment_gateways.map(gateway => {
                        if(gateway == 1) return 'paypal';
                        if(gateway == 4) return 'pagSeguro';
                    })
                    : [],
                isTrialDays: parseInt(state.tool.trial_days) > 0 ? true : false,
                tools: [],
                trialDays: parseInt(state.tool.trial_days) > 0 ? parseInt(state.tool.trial_days) : false,
                webhooks: state.tool.hasOwnProperty('webhooks') && state.tool.webhooks != ''
                    ? JSON.parse(state.tool.webhooks)
                    : [Helper.getEnvs().webhookDefaultPlan]
            }
        },
        product: (state, getters) => {
            return state.isBuyTool ? getters.adjustTool : state.isPlan ? getters.ajustePlano : getters.ajusteTaxa;
        },
        isPlan: (state) => {
            if(!state.plano.hasOwnProperty('titulo')){
                return false;
            }
            return true;
        },
        urlOfficeUser: (state) => {
            return `${Helper.getEnvs().urlOffice}/${state.user.lang}/office`;
        },
        getSlug: (state, getters) => {            
            let slug = router.currentRoute.params.slug;
            let position = slug.indexOf('&');

            return position <= 0
                ? slug
                : slug.substring(0, position);
        },
        hasCoupon: (state) => {
            return Object.keys(state.coupon).length >= 1 ? true : false;
        },
        isPlanToActive(state, getters){
            return getters.product.hasOwnProperty('plan_to_active') && getters.product['plan_to_active']['id_plan_to_activate'] != null;
        },
        isSponsorBuilderall(state, getters){
            return [
                Helper.builderallAccounts.internetMarketingLLC_10,
                Helper.builderallAccounts.internetMarketingLLC_30877,
                Helper.builderallAccounts.internetMarketingLLC_209282
            ].includes(state.userFather.id);
        },
        hasCouponAndIsValid: (state) => {
            return Object.keys(state.coupon).length >= 1 && state.coupon.isValid ? true : false;
        },
        hasMyCards: (state) => {
            return state.user.hasOwnProperty('cards') && state.user.cards != null ? true : false;
        },
        reasonCouponInvalid: (state, getters) => {
            if(getters.hasCouponAndIsValid) return '';
            
            if(state.coupon.reason == 'invalid_coupon') return getters.translateWord(`O cupom {0}, é invaldio.`, [state.coupon.code]);
            if(state.coupon.reason == '312_invalid_coupon') return getters.translateWord(`[312] Cupom {0} Inválido`, [state.coupon.code]);
            if(state.coupon.reason == '314_invalid_coupon') return getters.translateWord(`[314] Cupom {0} Inválido`, [state.coupon.code]);
            if(state.coupon.reason == 'coupon_code_is_required') return getters.translateWord(`Parametros insuficientes para verificação do codigo de cupom.`);
            if(state.coupon.reason == 'user_id_is_required') return getters.translateWord(`Não foi possivel localizar o usuario para este cupom.`);
            if(state.coupon.reason == 'this_coupon_is_not_valid_for_this_plan') return getters.translateWord(`O cupom {0}, não é válido para este plano.`, [state.coupon.code]);
            if(state.coupon.reason == 'coupon_invalid_date') return getters.translateWord(`Data de cupom inválida.`);
            if(state.coupon.reason == 'coupon_expired') return getters.translateWord(`O cupom {0}, esta expirado.`, [state.coupon.code]);
            if(state.coupon.reason == 'coupon_already_used') return getters.translateWord(`O cupom {0}, ja foi utilizado por você.`, [state.coupon.code]);
            if(state.coupon.reason == 'coupon_category_already_used') return getters.translateWord(`O cupom {0}, ja foi utilizado.`, [state.coupon.code]);
            if(state.coupon.reason == 'cannot_be_used_by_this_account') return getters.translateWord(`O cupom {0}, não pode ser usado por esta conta.`, [state.coupon.code]);
        },
        isExemptVat(state){
            return state.localeUser.isEmptyVat;
        },
        isChangeVat(state, getters){
            return  state.vatFee.percentage != 0 || state.vatFee.isValid || getters.isExemptVat ? true : false;
        },
        translateWord: (state) => (WordFind, ArrayReplace) => {
            let key = Helper.transformePalavraTraducao(WordFind);
            let word = state.translate[key];
            ArrayReplace = ArrayReplace || [];
            
            if(word == undefined){
                Store.dispatch('traduzirPalavra', {word: WordFind});
                return key;
            }

            ArrayReplace.forEach((wordArray, index) => {
                let regex = new RegExp('{['+index+']}', 'g');

                word = word.replace(regex, ArrayReplace[index])
            });

            return word;
        },
        getAllParamsUrl:(state) => (NewUrl, IsNewParams) => {
            NewUrl = NewUrl || null;
            IsNewParams = IsNewParams ? true : false;

            let url = NewUrl ? NewUrl : location.origin + location.pathname;
            let concatString = (url.indexOf('?') != -1) ? '&' : '?';
            let concatParams  = (location.search.length > 2) ? location.search.substring(1) : '';
            let aidHash = Helper.getParamHash('aid');

            concatString = concatParams != '' ? concatString : '';

            if(NewUrl && IsNewParams){
                concatParams += concatParams != ''
                    ? `&${NewUrl}`
                    : `?${NewUrl}`
            }

            //Verify get #aid hash
            if(aidHash) concatParams += `#aid=${aidHash}`;

            return NewUrl && !IsNewParams
                ? url + concatString + concatParams 
                : concatString + concatParams;
        },
        productAmount(state, getters){
            if(getters.product.amountCoupon) return getters.product.amountCoupon;
            if(getters.product.isAmountFirstMonth) return getters.product.amountFirstMonth;
            return getters.product.amount;
        },
        productAmountPlusVatFee: (state, getters) => (withoutCoupon = false, isAmountVatFeeWithoutCoupon = false, isAmountFirstMonth = false) => {
            let amount = 0.00;
            let amountVatFee = 0.00;

            if(getters.product.isAmountFirstMonth && isAmountFirstMonth) {
                amount = getters.product.amountFirstMonth;
            }else if(getters.product.amountCoupon && withoutCoupon){
                amount = getters.product.amountCoupon;
            }else{
                amount = getters.product.amount;
            }

            amountVatFee = isAmountVatFeeWithoutCoupon
                ? state.vatFee.amountWithoutCoupon
                : isAmountFirstMonth
                    ? state.vatFee.amountFirstMonth
                    : state.vatFee.amount;

            return (parseFloat(amount) + parseFloat(amountVatFee)).toFixed(2);
        },
        detectReferrer(state){
            let referrer = sessionStorage.getItem('referrer') || document.referrer;

            if(referrer != '' && !sessionStorage.getItem(referrer)) sessionStorage.setItem('referrer', referrer);

            return {
                isBuilderall: referrer == '' || referrer.search(/(br\.|es\.|it\.|fr\.|\b)+builderall.(com|de|local)/) != -1 ? true : false,
                domain: referrer
            };
        },
        linksPage: (state, getters) => (page) => {
            let lang = Helper.convertSigla(Helper.getLangBrowser());
            let linksPage = ['en', 'pt', 'es', 'it', 'fr', 'de'].includes(lang) ? LinksPage[page][lang] : LinksPage[page].en;
            let referrerDomain = getters.detectReferrer.domain;

            const replaceLinksDomain = (links) => {
                Object.keys(links).forEach((key) => {
                    if(typeof links[key] == 'object'){
                        replaceLinksDomain(links[key]);
                    }else{
                        if(links[key].search('{domain}') != -1){
                            links[key] = links[key].replace('{domain}', getters.detectReferrer.isBuilderall ? linksPage._defaultDomain : referrerDomain);
                        }

                        if(links[key].search('{domainPrimary}') != -1){
                            links[key] = links[key].replace('{domainPrimary}', getters.detectReferrer.isBuilderall ? linksPage._domainPrimary : referrerDomain);
                        }
                    }
                });

                return links;
            }

            linksPage = replaceLinksDomain(linksPage);
            
            return linksPage;
        },
        isCoupon100Percent(state){
            return state.coupon.type == 'PERCENTAGE_OF_DISCOUNT' && state.coupon.value == 100 ? true : false;
        },
        isCouponPeriodTime(state){
            return state.coupon.periodTime ? true : false;
        },
        hasBundlePlan(state, getters){
            return getters.getSlug == 'bundle-plan'
        },
        hasBumpsellAndSelected(state){
            return state.bumpsell.length >= 1 && state.bumpsell.some(item => item.check) ? true : false;
        }
    },
    mutations: {
        setDataUser(state, data){
            Object.assign(state.user, data);
        },
        setDataUserFather(state, data){
            Object.assign(state.userFather, data);  
        },
        setDataPlan(state, data){
            Object.assign(state.plano, data);
        },
        setDataFee(state, data){
            Object.assign(state.taxa, data);
        },
        setDataTool(state, data){
            Object.assign(state.tool, data);
        },
        setDadosFatura(state, data){
            Object.assign(state.fatura, data);
        },
        setInvoicePlanToActive(state, invoice){
            state.invoicePlanToActive = invoice;  
        },
        setChavesTraducao(state, data){
            data.forEach(function(value){
                state.translate[value.key] = value.word;
            });
        },
        incrementPossibleFraud(state){
            ++state.totalPossibleFraud;
        },
        addAllData(state, { stateName, data }){
            state[stateName] = data;
        },
        addValueVat(state, { percentage, amount, amountWithoutCoupon, amountFirstMonth }){
            state.vatFee.percentage = percentage || 0;
            state.vatFee.amount = amount || 0.00;
            state.vatFee.amountFirstMonth = amountFirstMonth || 0.00;
            state.vatFee.amountWithoutCoupon = amountWithoutCoupon || 0.00;
            
        },
        addVatNumberUser(state, { vatNumberCompleted, vatNumber, vatCountry, vatCompanyName, vatCompanyAddress }){
            state.vatFee.vatNumberCompleted = vatNumberCompleted;
            state.vatFee.vatNumber = vatNumber;
            state.vatFee.vatCountry = vatCountry;
            state.vatFee.vatCompanyName = vatCompanyName;
            state.vatFee.vatCompanyAddress = vatCompanyAddress;

            state.vatFee.isValid = true;
            for(let value of Object.values(state.vatFee)){
                if(value == null){
                    state.vatFee.isValid = false;
                }
            }
        },
        resetVat(state){
            state.vatFee = {
                isValid: false,
                    percentage: 0,
                    amount: 0.00,
                    amountFirstMonth: 0.00,
                    amountWithoutCoupon: 0.00,
                    vatNumberCompleted: '',
                    vatNumber: null,
                    vatCountry: null,
                    vatCompanyName: null,
                    vatCompanyAddress: null
            }
        },
        setLocaleUser(state, { ip, country, region }){
            state.localeUser.ip = ip;
            state.localeUser.country = country;
            state.localeUser.region = region || null;
            
            /*if(Helper.listCountryEurope.includes(country) && Product.getProduto().currency == 'EUR'){
                state.localeUser.isEurope = true;
            }*/

            //Removido pois é para validar igual aos outros paises da europa
            /*if(country == Helper.countryDutch && Product.getProduto().currency == 'EUR'){
                state.localeUser.isDutch = true;
            }*/

            if(country == 'MY') state.localeUser.isMalaysia = true;
            if(country == 'CA') state.localeUser.isCanada = true;
            if(country == 'GF') state.localeUser.isFrenchGuiana = true;
            if(country == 'GB') state.localeUser.isUnitedKingdom = true;
            if(country == 'PF') state.localeUser.isFrenchPolynesia = true;
            
            if(region == 'Canarias') state.localeUser.isRegionCanarias = true;

            localeUser = state.localeUser;
        },
        setCoupon(state, coupon){
            state.coupon = coupon;
        },
        setFakeAmountPlanOrFee(state, fakeAmountPlanOrFee){
            state.fakeAmountPlanOrFee = parseFloat(fakeAmountPlanOrFee).toFixed(2);
        },
        hasInvoiceUrlValid(state, isValid){
            state.isInvoiceUrlValid = isValid;
        },
        definedCountryEuro(state, country){
            state.countryEuroDefined = country
        },
        /**
         * 
         * @param state
         * @param paymentMethod nome do metodo de pagamento selcionado. creditCard - sepa - paypal - Meus Cartoes (Quando ja tem cartão vinculado)
         */
        selectPaymentMethod(state, paymentMethod){
            //Reseta o cartao selecionado caso tenha cartao vinculado
            state.myCardSelectedPayment = {};

            if(paymentMethod == 'creditCard') return state.paymentMethodSelected = paymentMethod;
            if(paymentMethod == 'sepa') return state.paymentMethodSelected = paymentMethod;
            if(paymentMethod == 'paypal') return state.paymentMethodSelected = paymentMethod;
            if(paymentMethod == 'pagSeguro') return state.paymentMethodSelected = paymentMethod;
            if(paymentMethod == 'boleto') return state.paymentMethodSelected = paymentMethod;
            if(paymentMethod == 'pix') return state.paymentMethodSelected = paymentMethod;
            if(paymentMethod == 'myCards') return state.paymentMethodSelected = paymentMethod;

            throw `O metodo de pagamento ${paymentMethod}, não existe`;
        },
        setMyCardSelectedPayment(state, data){
              state.myCardSelectedPayment = data;
        },
        /**
         *
         * @param state
         * @param status ativar|desativa metodo de pagamento com cartoes vinculados
         */
        enabledMyCards(state, status){
            state.paymentMethodEnabled.myCards = status;
        },
        /**
         *
         * @param state
         * @param status ativar|desativa metodo de pagamento com cartão de credito
         */
        enabledCreditCard(state, status){
            state.paymentMethodEnabled.creditCard = status;
        },
        /**
         *
         * @param state
         * @param status ativar|desativa metodo de pagamento com sepa
         */
        enabledSepa(state, status){
            state.paymentMethodEnabled.sepa = status;
        },
        /**
         *
         * @param state
         * @param status ativar|desativa metodo de pagamento com paypal
         */
        enabledPaypal(state, status){
            state.paymentMethodEnabled.paypal = status;
        },
        /**
         *
         * @param state
         * @param status ativar|desativa metodo de pagamento com pagSeguro
         */
        enabledPagSeguro(state, status){
            state.paymentMethodEnabled.pagSeguro = status;
        },
        /**
         *
         * @param state
         * @param status ativar|desativa metodo de pagamento com boleto
         */
        enabledBoleto(state, status){
            state.paymentMethodEnabled.boleto = status;
        },
        /**
         *
         * @param state
         * @param status ativar|desativa metodo de pagamento com pix
         */
        enabledPix(state, status){
            state.paymentMethodEnabled.pix = status;
        },
        /**
         * 
         * @param state
         * @param isPlan se for plano é true, se for false é taxa
         */
        setIsPlan(state, isPlan){
            if(typeof isPlan !== 'boolean'){
                throw "setIsPlan, o parametro é apenas Boolean";
            }
            
            state.isPlan = isPlan;
        },
        /**
         *
         * @param state
         * @param isBuyTool Se a compra vai ser de ferramentas em especificas ou não
         */
        setIsBuyTool(state, isBuyTool){
            if(typeof isBuyTool !== 'boolean'){
                throw "setIsBuyTool, o parametro é apenas Boolean";
            }

            state.isBuyTool = isBuyTool;
        },
        /**
         *
         * @param state
         * @param isBuyTool Se a compra vai ser Plano ou Taxa
         */
        setIsBuyPlanOrFee(state, isBuyPlanOrFee){
            if(typeof isBuyPlanOrFee !== 'boolean'){
                throw "isBuyPlanOrFee, o parametro é apenas Boolean";
            }

            state.isBuyPlanOrFee = isBuyPlanOrFee;
        },
        enabledFullScreenLoading(state, data){
            if(typeof data['status'] !== 'boolean'){
                throw "enabledFullScreenLoading, o parametro é apenas Boolean";
            }
            
            state.fullScreenLoading.status = data['status'];
            state.fullScreenLoading.text = data['text'] || '';
        },
        setIsPaymentOnlyPaypal(state, status){
            state.isPaymentOnlyPaypal = status;
        },
        isPaymentUpLine(state, status){
            state.isFirstPaymentUpLine = status;
        },
        userFatherToReceiveByGateway(state, status){
            state.isUserFatherToReceiveByGateway = status;
        },
        setPaymentPlanWordpressCloud(state, isPaymentPlanWordpressCloud){
            state.isPaymentPlanWordpressCloud = isPaymentPlanWordpressCloud;
        },
        setPaymentCheetahTemplate(state, isPaymentCheetahTemplate){
            state.isPaymentCheetahTemplate = isPaymentCheetahTemplate;
        },
        setCheetahTemplateDetails(state, cheetahTemplateDetails){
            state.cheetahTemplateDetails = cheetahTemplateDetails;
        },
        setIsPaymentCountryPrice(state, {status, data}){
            state.isPaymentCountryPrice = status;

            state.planCountryPrice.planId = data.planId;
            state.planCountryPrice.currency = data.currency;
            state.planCountryPrice.amount = data.amount;
            state.planCountryPrice.symbol = data.symbol;
            state.planCountryPrice.currencyRef = data.currencyRef;
            state.planCountryPrice.amountRef = data.amountRef;
            state.planCountryPrice.fakeAmount = data.fakeAmount;

            if(data.amountFirstMonth > 0){
                state.planCountryPrice.amountFirstMonth = data.amountFirstMonth;
                state.planCountryPrice.amountFirstMonthRef = data.amountFirstMonthRef;
            }

            if(data.hasOwnProperty('annual')){
                state.planCountryPrice.annual = {
                    amount: data.annual.amount,
                    amountRef: data.annual.amountRef,
                    monthAmount: data.annual.monthAmount,
                    monthAmountRef: data.annual.monthAmountRef
                }
            }
        },
        setIsChargedAmount(state, status){
            state.isChargedAmount = status;
        },
        setIsPauseAccount(state, status){
            state.isPauseAccount = status;
        },
        addDataNote(state, data){
            let dataNote = {};
            
            if(!localStorage.getItem('dataNote')){
                localStorage.setItem('dataNote', JSON.stringify(data));
                return;
            }
            
            dataNote = JSON.parse(localStorage.getItem('dataNote'));
            
            //Primeira fileira do objeto
            for(let [key, value] of Object.entries(data)){
                if(typeof value == 'object' && dataNote[key] != undefined) {
                    let isSecondChain = false;
                    
                    //Segunda fileira do objeto
                    for(let [key2, value2] of Object.entries(value)){
                        if(typeof value2 == 'object'){
                            isSecondChain = true;
                            Object.assign(dataNote[key], {[key2]: value2});
                            continue;
                        }   
                    }
                    
                    if(isSecondChain) continue;
                    
                    Object.assign(dataNote[key], data[key]);
                    continue;
                }

                Object.assign(dataNote, {[key]: value});
            }

            localStorage.setItem('dataNote', JSON.stringify(dataNote));
        },
        clearDataNote(){
            localStorage.removeItem('idInvoice');
            localStorage.removeItem('dataNote');
        },
        setStatusEmptyVat(state, status){
            state.localeUser.isEmptyVat = status;
        },
        setPaymentDomain(state, isPaymentDomain){
            state.isPaymentDomain = isPaymentDomain;
        },
        setPaymentDomainRegistry(state, isPaymentDomainRegistry){
            state.isPaymentDomainRegistry = isPaymentDomainRegistry;
        },
        setPaymentStorage(state, isPaymentStorage){
            state.isPaymentStorage = isPaymentStorage;
        },
        setPaymentDomainExtraConnection(state, isPaymentDomainExtraConnection){
            state.isPaymentDomainExtraConnection = isPaymentDomainExtraConnection;
        },
        setBoletoDetails(state, {barCode, barCodeImage, qrCode, downloadPdf}){
            state.boleto.barCode = barCode;
            state.boleto.barCodeImage = barCodeImage;
            state.boleto.qrCode = qrCode;
            state.boleto.downloadPdf = downloadPdf;
        },
        setPixDetails(state, {qrCode, qrCodeUrl}){
            state.pix.qrCode = qrCode;
            state.pix.qrCodeUrl = qrCodeUrl;
        },
        clearCoupon(state){
            state.coupon = {}
        },
        /**
         * Função feita para manipular os action de prev/next do step. fora do componente
         *
         * @param state
         * @param prevOrNext prev, next ou true
         */
        changeStepAction(state, prevOrNext){
            if(!['prev', 'next', true].includes(prevOrNext)) return;

            state.stepAction = prevOrNext;
        },
        changePeriodRecurring(state, period){
            let plan = state.plano;
            state.plano = {};

            if(period == 'MONTHLY'){
                plan.total_real = state.planCountryPrice.amount;
                plan.periodo = 'MENSAL';
            }

            if(period == 'YEARLY'){
                plan.total_real = state.planCountryPrice.annual.amount;
                plan.periodo = 'ANUAL';
                
                state.coupon = {};
            }

            state.plano = plan;
        },
        setBgResponse(state, { payment, future }){
            if(payment) state.bgResponse.payment = payment;

            if(future) state.bgResponse.future = future;
        },
        setDevMode(state, status){
            state.devMode = status;
        },
        setMyCreditCard(state, cards){
            state.user.credit_cards = cards;
        },
        setBumpsell(state, bumpsellItens){
            bumpsellItens = bumpsellItens.map(data => ({...data, price: parseFloat(data.price)}));
            state.bumpsell = bumpsellItens;
        },
        setBumpsellPrice(state, price){
            state.bumpsellPrice = price;
        },
        setTokenCheckout(state, token){
            state.tokenCheckout = token;
        }
    },
    actions: {
        async getTranslation(state, {sigla, category}){
            sigla = Helper.convertSigla(sigla);

            let requestLang = (await Vue.prototype.$httpTranslation.post('/translate/all', {
                application: Helper.translateVersion,
                sigla,
                category
            })).data;
            
            if(requestLang.status == 'error' && requestLang.hasOwnProperty('message') && requestLang.message == 'key_not_found'){
                requestLang = (await Vue.prototype.$httpTranslation.post('/translate/all', {
                    application: Helper.translateVersion,
                    sigla: Helper.langDefault,
                    category
                })).data;   
            }
            
            if(requestLang.status === 'success') return state.commit('setChavesTraducao', requestLang.data);
        },
        traduzirPalavra(state, { word }){
            return Vue.prototype.$httpTranslation.post('/translate', {
                application: Helper.translateVersion,
                word: word
            }).then(response => {
                 console.log(response);
            });
        },
        async verifyPixelFinishBuy({ state, getters }){
            return await (new Promise(async (resolve, reject) => {
                let pixelDetected = false;
                
                if(PixelAnalytics.pixelEnabled.facebookPixel) {
                    if(PixelAnalytics.fbData.accessToken){
                        let pixelFbPurchase = await generateFacebookEventId('Purchase');
                        
                        sendPurchaseEvent(
                            PixelAnalytics.fbData.accessToken,
                            PixelAnalytics.fbData.id,
                            PixelAnalytics.fbData.funnelsDomain,
                            pixelFbPurchase,
                            Product.getProduto().currency,
                            getters.productAmount,
                            Product.getProduto().title,
                            Helper.firstName(state.user.name),
                            Helper.lastName(state.user.name),
                            state.user.email,
                            PixelAnalytics.fbData.ur,
                            PixelAnalytics.fbData.subdomain
                        )

                    }
                    
                    pixelDetected = true;
                    
                    console.log('Rodou pixel do Facebook | Purchase');
                }

                // Removido porque é enviado no step de pagamento confirmado
                //
                // if(PixelAnalytics.pixelEnabled.googleAnalytics) {
                //     PixelAnalytics.createEventPixel('ga', null, {
                //         hitType: 'event',
                //         location: location.href,
                //         page: location.pathname,
                //         eventCategory: 'Purchase',
                //         eventAction: 'click',
                //         eventLabel: `Purchase of ${Product.getProduto().titulo} ${Product.getProduto().currency}${Product.getProduto().amountCoupon ? Product.getProduto().amountCoupon : Product.getProduto().valor}`
                //     });
                    
                //     pixelDetected = true;

                //     console.log('Rodou pixel do Google Analytics | Purchase');
                // }
                
                setTimeout(() => resolve(true), 1000);
            }));
        },
        /**
         * 
         * @param state
         * @param commit
         * @param getters
         * @param couponUser
         * @param isLoadingCheckout Se esta validando o cupom no carregamento do checkout ou não.
         * @returns {Promise<void>}
         */
        async validateCoupon({ state, commit, getters, dispatch }, { couponUser, isLoadingCheckout, isPagePlan, planId }){
            isPagePlan = isPagePlan || false;
            planId = getters.product.id || planId;

            let qs = `coupon_code=${couponUser}&user_id=${state.user.user_identifier}&plan_id=${planId}&plan_period=${getters.product.isBuyAnnual ? 'ANUAL' : 'MENSAL'}`;

            if(!isLoadingCheckout){
                commit('enabledFullScreenLoading', { status: true, text: getters.translateWord('Verificando se o coupon é valido') });
            }
            
            let coupon = (await Vue.prototype.$httpOffice.post(state.endpointOffice.CHECK_COUPON, qs, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })).data;

            if(coupon.isValid && coupon.coupon.type == 'PERCENTAGE_OF_DISCOUNT'){
                coupon.coupon.value = coupon.coupon.value * 100;
            }

            if(coupon.hasOwnProperty('isValid')){
                if(!isLoadingCheckout) commit('enabledFullScreenLoading', { status: false });

                let period = null;
                let periodTime = null;
                switch(coupon.coupon.period){
                    case 'DAYS':
                        period = 'TIME_DAY';
                        break;
                    case 'WEEKS':
                        period = 'TIME_WEEK';
                        break;
                    case 'MONTHS':
                        period = 'TIME_MONTH';
                        break;
                    case 'YEARS':
                        period = 'TIME_YEAR';
                        break;
                }

                periodTime = coupon.coupon.period_value;

                if(coupon.coupon.apply_only_first_month == 1){
                    period = 'FIRST_MONTH';
                    periodTime = null;
                }

                if(coupon.coupon.apply_only_first_month == 0 && !period && !periodTime){
                    period = 'FOREVER';
                    periodTime = null;
                }

                commit('setCoupon', {
                    id: coupon.coupon.id,
                    code: coupon.coupon.code || couponUser,
                    value: coupon.coupon.value,
                    type: coupon.coupon.type,
                    apply_only_first_month: coupon.coupon.apply_only_first_month,
                    isValid: coupon.isValid,
                    reason: coupon.reason,
                    period: period,
                    periodTime: periodTime,
                });

                sessionStorage.setItem('coupon', coupon.coupon.code || couponUser);

                //Verifica se tem VAT
                if(!isPagePlan && getters.product.isTheCurrency.eur && getters.isChangeVat){
                    commit('addValueVat', {
                        percentage: state.vatFee.percentage,
                        amount: Helper.calculateVat(getters.productAmount, state.vatFee.percentage),
                        amountFirstMonth: Helper.calculateVat(getters.product.amountFirstMonth, state.vatFee.percentage),
                        amountWithoutCoupon: Helper.calculateVat(getters.product.amount, state.vatFee.percentage)
                    });
                }
            }

            if (!isPagePlan && !isLoadingCheckout && coupon.isValid) {
                commit('addDataNote', {
                    nota: {
                        coupon_id: coupon.coupon.id,
                        coupon_code: coupon.coupon.code,
                        coupon_type: coupon.coupon.type,
                        coupon_value: coupon.coupon.value,
                        coupon_apply_only_first_month: coupon.coupon.apply_only_first_month
                    }
                });
            }
        },
        async updateNoteInvoice({ state }){
            let dataNote = JSON.parse(localStorage.getItem('dataNote'));
            let idInvoice = localStorage.getItem('idInvoice');
            
            if(!dataNote && !idInvoice) return;
            
            await Vue.prototype.$httpOffice.post(`${state.endpointOffice.UPDATE_INVOICE}/${idInvoice}`, dataNote);
        },
        /**
         * Função para validar o plano atual do usuario. porque as vezes fica gravado na sessao do office e nao retorna o plano atual,
         * quando por exemplo: o usuario compra um plano e volta pro checkout, ele vem com o plano antigo, e nao oque acabou de comprar.
         * 
         * @param state
         * @returns {Promise<void>}
         */
        async verifyCurrentPlanUser({ state, commit }){
            try{
                let userCurrentPlan = (await Vue.prototype.$httpOffice.get(`${state.endpointOffice.GET_CURRENT_PLAN_USER}/${state.user.user_identifier}`)).data.plan;
                if(userCurrentPlan.length !== 0){
                    commit('setDataUser', {
                        current_plan: {
                            id: userCurrentPlan.id,
                            slug: userCurrentPlan.slug,
                            theme_type: userCurrentPlan.theme_type,
                            title: userCurrentPlan.titulo
                        }
                    });
                }
            }catch(error){
                console.log(error);
            }
            
            return true;
        },
        async getLocaleUserAccess({ state, commit }, country){
            let regionUser = null;
            if(['dev'].includes(Helper.getEnvs().env)){
                regionUser =  { ip: '127.0.0.1', country_code: 'BR', region: 'Minas Gerais' };
                //regionUser =  { ip: '127.0.0.1', country_code: 'ES', region: 'Madrid' };
                //regionUser =  { ip: '127.0.0.1', country_code: 'GF', region: 'Guyane' }; //Guiana Francesa
                //regionUser =  { ip: '127.0.0.1', country_code: 'NL', region: 'Zuid-Holland' }; //Holanda
                //regionUser =  { ip: '127.0.0.1', country_code: 'ES', region: 'Canarias' }; //Canarias
                //regionUser =  { ip: '127.0.0.1', country_code: 'GB', region: 'England' }; //United Kingdom (GB)
                //regionUser =  { ip: '127.0.0.1', country_code: 'PF', region: 'Wilayah Persekutuan Kuala Lumpur' }; //French Polynesia
                //regionUser =  { ip: '127.0.0.1', country_code: 'MY', region: 'Wilayah Persekutuan Kuala Lumpur' }; //Malasia
            }else{
                regionUser = (await Vue.prototype.$httpOffice.get(`${state.endpointOffice.LOCALE_BY_IP}`)).data
            }

            commit('setLocaleUser', {
                ip: regionUser.ip,
                country: country || regionUser.country_code,
                region: regionUser.region
            });

            return true;
        },
        async getAllOfficeCountries({ state }){
            return (await Vue.prototype.$httpOffice.get(`${state.endpointOffice.SUBSIDIARIES}`)).data.data;
        },
        async getCountryPrices({ state, commit, dispatch }, country){
            let countryUser = null;
            let lang = Helper.convertCountryCodeToOffice(Helper.getLangBrowser());
            let tokenCountryPrice = '8dXZ3m1mE4GxXnA92kSLuHEsFlxEMMlUckvkkyMJsukcivOAEgumMUm4Aj9gZDqNajPgvEIW2cTVdPJIcHIpsABCYTvWUBUl91oQYLubV9o8sg';

            if(country){
                //1 - Pegar o IP do usuario
                await dispatch('getLocaleUserAccess', country ? country.toString().toUpperCase() : null);

                //2 - Pega todas os countries existentes no office e filtra pelo que existe no office
                let countries = await dispatch('getAllOfficeCountries');

                countryUser = countries.filter(country => country.iso_code == state.localeUser.country)[0];
            }

            //3 - Retorna o Country Prices passando o id da subsidiaria
            if(Helper.getEnvs().env == 'dev'){
                return (await Vue.prototype.$httpOffice.get(`https://office.builderall.com/us/office/api/country-prices/${country ? countryUser.id : ''}?user_lang=${lang}&api_token=${tokenCountryPrice}`)).data.data;
            }

            return (await Vue.prototype.$httpOffice.get(`${state.endpointOffice.COUNTRY_PRICE}/${country ? countryUser.id : ''}?user_lang=${lang}&api_token=${tokenCountryPrice}`)).data.data;
        },
        async verifyTranslationPage({dispatch}, page) {
            let lang = Helper.convertSigla(Helper.getLangBrowser());

            //Temporario ate traduzir o polones
            lang = lang == 'pl' ? 'en' : lang;

            await dispatch('getTranslation', {
                sigla: lang,
                category: page
            });

            return true;
        },
        verifyPixelBuilderall(){
            let envProd = Helper.getEnvs().env == 'prod' ? true : false;

            if(envProd && router.currentRoute.query['skipStepConfirmation']) return;

            PixelAnalytics.googleAnalytics4(envProd ? 'G-20WR450BYB' : 'G-LTJRS92H6S');

            setTimeout(() => PixelAnalytics.googleTagManager(envProd ? 'GTM-KFN45NN' : 'GTM-W892GB7K'), 4000);
        },
        // async convertAmountPauseAccountPaypal({ state, commit, dispatch }, currencyBase, amount){
        //     //Realizar a conversao em dolar

        //     }
        // },
        async verifyPixelAnalytics({ state, getters }, { aid, subdomain }){
            let pixelConfigureUser = (await Vue.prototype.$httpOffice.get(`${state.endpointOffice.GET_PIXEL_FUNNEL}?aid=${aid}&subdomain=${subdomain}`)).data;
            let pixelLead = router.currentRoute.query.pixelEvent || null;
            let ur = router.currentRoute.query.ur || null;

            if(Array.isArray(pixelConfigureUser.data.pixels)){
                pixelConfigureUser.data.pixels.forEach(async pixel => {
                    if(pixel.type == 'facebook'){

                        if(pixel.hasOwnProperty('access_token') && pixel['access_token'] != ''){
                            PixelAnalytics.setFbData(
                                pixel.id,
                                pixel.access_token,
                                pixelConfigureUser.data.funnels_domain,
                                ur,
                                subdomain || null
                            );

                            let pixelFbPageView = await generateFacebookEventId('PageView');

                            sendPageViewEvent(
                                PixelAnalytics.fbData.accessToken,
                                PixelAnalytics.fbData.id,
                                PixelAnalytics.fbData.funnelsDomain,
                                pixelFbPageView,
                                PixelAnalytics.fbData.ur,
                                PixelAnalytics.fbData.subdomain
                            );

                            PixelAnalytics.facebookPixel(pixel.id); //265128684601309

                            PixelAnalytics.createEventPixel('fb', 'PageView', {}, pixelFbPageView);

                            if(state.user.isLogged){
                                if(pixelLead) PixelAnalytics.createEventPixel('fb', pixelLead);
                            }

                        }else{
                            PixelAnalytics.facebookPixel(pixel.id); //265128684601309

                            PixelAnalytics.createEventPixel('fb', 'PageView');

                            if(pixelLead) PixelAnalytics.createEventPixel('fb', pixelLead);
                        }
                    }

                    if(pixel.type == 'google-analytics') {
                        PixelAnalytics.googleAnalytics(pixel.id); //GA-12764628

                        if(pixelLead) PixelAnalytics.createEventPixel('ga', null, {
                            hitType: 'event',
                            location: location.href,
                            page: location.pathname,
                            eventCategory: pixelLead
                        });
                    }

                    if(pixel.type == 'google-tag-manager') {
                        PixelAnalytics.googleTagManager(pixel.id); //UA-136599186-1
                    }
                });
            }

            if(pixelConfigureUser.data.tracking_pixel_url){
                PixelAnalytics.insertDirectDom(pixelConfigureUser.data.tracking_pixel_url);
            }

            if(pixelLead) PixelAnalytics.addDataLayer(pixelLead);
        },
        async verifyPixelAnalyticsParent({ state, getters }){
            let pixelLead2 = router.currentRoute.query['pixel-event'] || null;

            if(!pixelLead2) return;

            let pixelConfigureUser = (await Vue.prototype.$httpOffice.post(state.endpointOffice.GET_PARENT_PIXEL, Helper.serialize({
                event: pixelLead2,
                user_id: state.user.user_identifier
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })).data;

            if(!pixelConfigureUser.success) return;
            pixelConfigureUser = pixelConfigureUser.data;

            if(pixelConfigureUser.facebook){
                PixelAnalytics.facebookPixel(pixelConfigureUser.facebook);

                console.log(`Pixel Facebook: ${pixelConfigureUser.facebook}`);
            }

            if(pixelConfigureUser.google_analytics){
                PixelAnalytics.googleAnalytics(pixelConfigureUser.google_analytics);

                console.log(`Pixel Google Analitcs: ${pixelConfigureUser.google_analytics}`);
            }

            if(pixelConfigureUser.google_tag_manager){
                PixelAnalytics.googleTagManager(pixelConfigureUser.google_tag_manager);

                console.log(`Pixel Google Tag Manager: ${pixelConfigureUser.google_tag_manager}`);
            }
        }
    }
});