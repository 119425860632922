<template>
    <div class="checkout">

        <div class="checkout_left" v-if="stepEmitName != 'paymentConfirmation'">
            <div class="checkout_left_content">
                <div v-show="!stepEmitName || stepEmitName == 'planOrFee'" class="PlanOrFeeComplements">
                    <PlanOrFeeComplements />
                </div>

                <div v-if="stepEmitName == 'payment'" class="PaymentComplements">
                    <PaymentComplements />
                </div>
            </div>
        </div>

        <div class="checkout_right" :class="{checkout_rightPaymentConfirmation: stepEmitName == 'paymentConfirmation'}">
            <div class="checkout_right_content">
                <Steps
                        @step-name="stepName"
                        :user-father="userFather"
                        :name-back="translateWord('Voltar')"
                        :name-indicated="translateWord('Você foi indicado por') + ':'">
                    <Step condition="show"
                          :name="isPlan || isBuyTool ? translateWord('Plano') : translateWord('Taxa')"
                          :active="stateStep.planOrFeeDetails.active"
                          :finish="stateStep.planOrFeeDetails.finish"
                          :icon="stateStep.planOrFeeDetails.icon">

                        <PlanOrFee @step-emit="stepEmit"/>
                    </Step>

                    <Step :name="translateWord('Pagamento')"
                          :active="stateStep.payment.active"
                          :finish="stateStep.payment.finish"
                          :icon="stateStep.payment.icon">
                        <Payment @step-emit="stepEmit"/>
                    </Step>
                    <Step :name="translateWord('Confirmação')"
                          color="green"
                          :active="stateStep.paymentConfirmation.active"
                          :finish="stateStep.paymentConfirmation.finish"
                          :icon="stateStep.paymentConfirmation.icon">
                        <PaymentConfirmation/>
                    </Step>
                </Steps>
            </div>
        </div>

        
        <!--<p class="checkout_ipUser">IP: {{ localeUser.ip }} | {{ localeUser.country }}</p>-->
    </div>
</template>


<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import Steps from '../components/step-by-step/Steps';
import Step from '../components/step-by-step/Step';
import Loading from '../components/Loading';
import Box from '../components/Box';

import PlanOrFee from '../views/PlanOrFee';
import PlanOrFeeComplements from '../views/PlanOrFeeComplements';
import Payment from '../views/Payment';
import PaymentComplements from '../views/PaymentComplements';
import PaymentConfirmation from '../views/PaymentConfirmation';

export default {
    name: 'checkout',
    data(){
        return {
            stepEmitName: null,
            stateStep: {
                planOrFeeDetails: {
                    active: true,
                    finish: false,
                    icon: 'arrow'
                },
                payment: {
                    active: false,
                    finish: false,
                    icon: 'arrow'
                },
                paymentConfirmation: {
                    active: false,
                    finish: false,
                    icon: 'arrow'
                }
            },
            backEnabled: false
        }
    },
    created(){
        //Criar pagamento com desconto para SEPA e implementar tbm a feature de pagamento futuro
        if(['promocao1euro', 'promotion-plan-59-eur'].includes(this.$route.params.slug)){
            this.isTempHideSepa = true;
        }
        
        if(this.isInvoiceUrlValid !== null && this.isInvoiceUrlValid){
            
            this.$Notify.info({
                title: this.translateWord('Invoice invalida'),
                message: this.translateWord('A Invoice {0}, não pertece a seu usuario. Criamos uma nova para voce efetuar a compra!', [
                    this.$route.query.invoice || null
                ]),
                duration: 6000
            });
        }
        
        if(localStorage.getItem('canSkipStep') && this.$route.query['checkoutStep'] !== undefined){
            if(this.$route.query['checkoutStep'] == 'PaymentConfirmation'){
                this.stateStep.planOrFeeDetails.active = false;
                this.stateStep.planOrFeeDetails.finish = true;

                this.stateStep.payment.finish = true;

                this.stepEmit('paymentConfirmation');
                
                this.stateStep.paymentConfirmation.active = true;
            }

            if(this.$route.query['checkoutStep'] == 'Payment'){
                this.stateStep.planOrFeeDetails.active = false;
                this.stateStep.planOrFeeDetails.finish = true;

                this.stepEmit('payment');

                this.stateStep.payment.active = true;
            }

            if(this.$route.query['paymentMethod'] == 'paypal'){
                this.selectPaymentMethod('paypal');
            }
        }

        //TODO remover pois é apenas para testes
        if(this.$route.query['checkoutStep'] == 'Payment'){
            this.stateStep.planOrFeeDetails.active = false;
            this.stateStep.planOrFeeDetails.finish = true;

            this.stepEmit('payment');

            this.stateStep.payment.active = true;
        }
        
        if(this.$route.query['skipStepConfirmation'] == 'testUrl'){
            this.stateStep.planOrFeeDetails.active = false;
            this.stateStep.planOrFeeDetails.finish = true;

            this.stateStep.payment.finish = true;

            this.stepEmit('paymentConfirmation');

            this.stateStep.paymentConfirmation.active = true;

            if(!localStorage.getItem('canSkipStep') && !localStorage.getItem('redirectUrlAfterPayment')){
                localStorage.setItem('canSkipStep', true);
                localStorage.setItem('redirectUrlAfterPayment', this.product.redirect);   
            }
        }
    },
    computed:{
        ...mapState([
            'user',
            'userFather',
            'localeUser',
            'isPlan',
            'isBuyTool',
            'coupon',
            'isInvoiceUrlValid',
            'fatura',
            'countryEuroDefined',
            'endpointGateway'
        ]),
        ...mapGetters([
            'urlOfficeUser',
            'product',
            'translateWord'
        ]),
    },
    methods:{
        ...mapMutations([
            'selectPaymentMethod'
        ]),
        changeStep(){
            this.stateStep.paymentConfirmation.active = true;
            this.stateStep.paymentConfirmation.finish = true;
        },
        stepName(name){
            this.backEnabled = name == 'Pagamento' ? true : false; 
        },
        stepEmit(stepName){
            this.stepEmitName = stepName;
        }
    },
    components: {
        Loading,
        Steps,
        Step,
        Box,
        PlanOrFee,
        PlanOrFeeComplements,
        Payment,
        PaymentComplements,
        PaymentConfirmation
    }
}
</script>

<style lang="scss">
    @import "../scss/mixins";

    .checkout{
        display: flex;
        justify-content: center;
        margin: auto;
        min-height: 100%;
    }

    .checkout_left{
        display: flex;
        justify-content: flex-end;
        width: 50%;
        background-color: var(--stepLeftColor);
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .checkout_left_content{
        width: 710px;
        padding: 0px 100px;
    }

    .checkout_right{
        display: flex;
        width: 50%;
        padding-top: 60px;
        padding-bottom: 100px;
    }
    .checkout_right_content{
        width: 710px;
        padding: 0px 100px;
    }

    .checkout_rightPaymentConfirmation{
        width: inherit;
        justify-content: center;
        align-items: flex-start;
        padding-top: 20px;

        & .checkout_right_content{
            width: 900px;
            position: relative;
            padding: 30px;
            border: 1px solid var(--gray-100);

            &:before{
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 135px;
                background: #1869F6;
            }
        }

        & .steps_header_logo{
            width: 200px;
            border-radius: 15px;
            box-shadow: 10px 10px 20px rgba(91, 91, 91, 0.2);
            padding: 30px;
            background: var(--white);
            z-index: 10;
        }
    }

    @include media(sm,md,lg){
        .checkout{
            flex-direction: column-reverse;
        }

        .checkout_left{
            width: 100%;
            justify-content: flex-start;
            padding: 0px;
        }

        .checkout_right{
            width: 100%;
            padding-top: 0px;
            padding-bottom: 0px;
        }

        .checkout_left_content{
            width: 100%;
            padding: 30px;
        }

        .checkout_right_content{
            width: 100%;
            padding: 30px
        }
    }

    @include media(md,lg){
        .checkout_left{
            width: 100%;
            justify-content: center;
        }
        .checkout_left_content{
            width: 70%;
            padding: 30px;
        }

        .checkout_right{
            width: 100%;
            justify-content: center;
        }
        .checkout_right_content{
            width: 90%;
            padding: 30px
        }
    }
</style>
