<template>
    <div class="fullScreenLoading">
        
        <div class="fullScreenLoading_image">
            <img class="loadingImage" src="../assets/imagem/loading-2.svg" alt="">

            <p class="fullScreenLoading_info"> {{ text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FullScreenLoading",
    props: ['text']
}
</script>

<style scoped lang="scss">
    .fullScreenLoading{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.7);
        z-index: 99;
        text-align: center;
        color: #ffffff;
    }
    
    .fullScreenLoading_image{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        & img{
            width: 80px;
        }
    }
</style>