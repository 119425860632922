<template>
    <div class="accountDetails">
        <div class="accountDetails_content">
            <img class="accountDetails_image" :src="user.avatar" />
            <p class="accountDetails_name">{{ user.name }}</p>
            <p class="accountDetails_email">{{ user.email }}</p>
        </div>

        <div class="accountDetails_actions">
            <at-button class="btn_formWidth" @click.prevent="$emit('account-details:continue')" type="primary" size="large" :disabled="disabledBtnContinue">{{ translateWord('Continuar') }}</at-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: "AccountDetails",
    props: {
        disabledBtnContinue: {
            type: Boolean
        }  
    },
    data(){
        return {}
    },
    computed: {
        ...mapState([
            'user',
            'fatura'
        ]),
        ...mapGetters([
            'translateWord'
        ])
    }
}
</script>

<style lang="scss">
    @import "../../scss/mixins";

    .accountDetails{
        margin: 40px auto;
        color: #505050;
    }

    .accountDetails_content{
        display: flex;
        align-items: center;
        flex-direction: column;
        font-weight: 600;
    }

    .accountDetails_image{
        width: 150px;
        border-radius: 10px;
        border: 1px solid #D9D9D9;
    }
    .accountDetails_name{
        margin-top: 23px;
        text-transform: uppercase;
    }
    .accountDetails_email{
        margin-top: 6px;
        font-size: 0.8em;
    }

    .accountDetails_actions{
        margin-top: 25px;
    }

    @include media(sm,md){
        .accountDetails_actions{
            position: fixed;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            margin-bottom: 10px;
        }
    }
</style>