<template>
    <svg class="icon">
        <use :xlink:href="href"></use>
    </svg>
</template>


<script>
    export default {
        name: 'Icons',
        data(){
            return {
                href: require('../assets/icons/icons/icons.svg')
            }
        },
        props: {
            name: String
        },
        created(){
            this.href += `#${this.name}`;
        }
    }
</script>