<template>
    <!--<div class="box" :class="[{boxPaddingBigTopBottom: paddingBig}, {boxTransparent}, {noPadding}]">
        
        <div class="boxArrow" :class="{boxArrowAccordionActive: visible}" @click.prevent="showAccordion" v-if="this.$slots.hasOwnProperty('accordion')"></div>
        
        <slot></slot>
        
        <div class="box_accordion" v-show="visible" v-if="this.$slots.hasOwnProperty('accordion')">
            <slot name="accordion"></slot>
        </div>

        <slot name="footer"></slot>
    </div>-->
</template>

<script>
    export default {
        name: "Box",
        props: {
            paddingBig: {
                type: Boolean
            },
            boxTransparent: {
                type: Boolean
            },
            noPadding: {
                type: Boolean
            }
        },
        data(){
            return {
                visible: false
            }  
        },
        created(){
            
        },
        methods: {
            showAccordion(){
                this.visible = !this.visible;
            }
        }
    }
</script>