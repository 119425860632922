<template>
    <div class="blockFraud">

        <div class="blockFraud_header">
            <i class="blockFraud_header_icone icon icon-lock"></i>
            <h1 class="blockFraud_header_titulo">Conta bloqueada</h1>
            <p class="blockFraud_header_desc">
                Você foi avisado <b>{{ attempted }}x</b> que suas tentativas de pagamento era suspeita de fraude, devido a este motivo<br>
                sua conta <b>{{ user.email }}</b> esta sendo bloqueada!
            </p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Help from '../assets/js/helper';

export default {
    name: 'UserBlockFraud',
    data(){
        return {
            attempted: Help.possibleFraudAttempts
        }
    },
    computed: {
        ...mapState(['user'])
    }
    
}
</script>