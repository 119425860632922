<template>
    <div class="notFoundPlanOrFee">

        <!--<div class="notFoundPlanOrFee_header">
            <i class="notFoundPlanOrFee_header_icone icon icon-info"></i>
            <h1 class="notFoundPlanOrFee_header_titulo">{{ translateWord('Plano/Taxa não encontrado') }}</h1>
            <p class="notFoundPlanOrFee_header_desc">{{ translateWord('O Plano ou taxa que você acessou não existe.') }}</p>
        </div>-->
        
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: "NotFoundPlanOrFee",
    data(){
        return {}  
    },
    computed: {
        ...mapState([
            'user'
        ]),
        ...mapGetters([
            'translateWord'
        ])
    },
    created(){
        window.location.href = 'https://builderall.com'
    },
    components: {}
}
</script>