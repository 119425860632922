<template>
    <div class="detailsDomain">
        <div class="detailsDomain_domain">
            <p class="detailsDomain_domain_name">
                <i class="icon icon-globe"></i> <span>{{ product.domain }}</span>

                <span class="detailsDomain_domain_name_available">{{ translateWord('Domínio Disponivel') }}</span>
            </p>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "DetailsDomain",
    computed: {
        ...mapGetters([
            'translateWord',
            'product'
        ])
    }
}
</script>

<style lang="scss">
    @import "../../scss/mixins";

    .detailsDomain_domain{
        text-align: center;
    }
    .detailsDomain_domain_name{
        position: relative;
        display: inline-block;
        color: var(--green);
        font-size: 1.3em;

        & .icon{
            vertical-align: middle;
        }

    }
    .detailsDomain_domain_name_available{
        position: absolute;
        top: 30px;
        right: 0px;
        font-size: 0.4em;
        text-transform: uppercase;
    }
</style>