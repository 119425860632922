<template>
    <div class="stamps">
        <div class="stamps_item">
            <icon class="stamps_item_icon" name="handMoney" />
            <p class="stamps_item_name">{{ translateWord('30 Dias seu dinheiro de volta') }}</p>
        </div>

        <div class="stamps_item">
            <icon class="stamps_item_icon" name="course" />
            <p class="stamps_item_name">{{ translateWord('30 Horas de cursos e treinamentos') }}</p>
        </div>

        <div class="stamps_item">
            <icon class="stamps_item_icon" name="people" />
            <p class="stamps_item_name">{{ translateWord('Grupo de 60 mil empresários integrantes') }}</p>
        </div>

        <div class="stamps_item">
            <icon class="stamps_item_icon" name="charts" />
            <p class="stamps_item_name">{{ translateWord('Funciona para todos os negócios') }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Stamps",
    computed: {
        ...mapGetters([
            'translateWord'
        ])
    }
}
</script>

<style lang="scss">
    .stamps{
        display: flex;
        justify-content: space-between;
    }
    .stamps_item{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-right: 15px;

        &:last-child{
            margin-right: 0px;
        }
    }
    .stamps_item_icon{
        font-size: 2.5em;
    }
    .stamps_item_name{
        font-size: 0.7em;
        margin-top: 10px;
    }
</style>