<template>
    <div class="sponsor">
        <div class="sponsor_image">
            <img :src="userFather.avatar" :alt="userFather.name" :title="userFather.name">
        </div>

        <div class="sponsor_info">
            <p>{{ translateWord('Você foi indicado por.') }}</p>
            <p class="sponsor_info_name">{{ userFather.name }}</p>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: "Sponsor",
    computed: {
        ...mapState([
            'userFather'
        ]),
        ...mapGetters([
            'translateWord'
        ])
    }
}
</script>

<style lang="scss">
    .sponsor{
        display: flex;
        align-items: center;
        font-size: 0.8em;
        color: var(--black);
        margin-left: 10px;
    }

    .sponsor_image{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 3px;
        overflow: hidden;
        border: 1px solid #D9D9D9;
        margin-right: 10px;

        & img{
            width: 50px;
            border-radius: 50%;
        }
    }

    .sponsor_info_name{
        font-size: 1.3em;
        font-weight: bold;
        text-transform: uppercase;
    }
</style>