<template>
    <div class="bumpsell">
        <div v-for="(item, index) in bumpsell" class="bumpsell_items" :key="index">
            <div class="bumpsell_items_header">
                <div class="bumpsell_items_header_checkbox">
                    <at-checkbox v-model="item.check" @on-change="checkItemBumpsell(item)"></at-checkbox>
                </div>

                <div class="bumpsell_items_header_title">
                    {{ item.title }}
                </div>

                <div class="bumpsell_items_header_price">
                    {{ product.currencySymbol }}{{ item.price.toFixed(2) }}
                </div>
            </div>

            <div class="bumpsell_items_description">
                {{ item.description }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
    name: "Bumpsell",
    props: {
    },
    data(){
        return {
            visible: false
        }  
    },
    created(){

    },
    computed: {
        ...mapState([
            'bumpsell',
            'bumpsellPrice'
        ]),
        ...mapGetters([
            'product'
        ])
    },
    methods: {
        ...mapMutations([
            'setBumpsell',
            'setBumpsellPrice'
        ]),
        checkItemBumpsell(item){
            if(item.check) return this.setBumpsellPrice(this.bumpsellPrice + item.price)

            if(!item.check) return this.setBumpsellPrice(this.bumpsellPrice - item.price);
            
        }
    }
}
</script>

<style lang="scss">
    .bumpsell{
        margin-top: 30px;
    }
    .bumpsell_items{
        margin-bottom: 20px;
    }

    .bumpsell_items_header{
        display: flex;
    }
    .bumpsell_items_header_checkbox{
        margin-top: -7px;
    }
    .bumpsell_items_header_title{
        color: var(--blue);
        font-weight: 600;
    }
    .bumpsell_items_header_price{
        margin-left: auto;
        font-size: 1.4em;
        font-weight: 600;
    }
    .bumpsell_items_description{
        font-size: 0.8em;
        margin-left: 26px;
    }
</style>