<template>
    <!--<div class="planOrFeeDetails_recoverPassword">
        <h1>Recuperar senha</h1>

        <div class="planOrFeeDetails_recoverPassword_form">
            <at-input v-model="email" :placeholder="translateWord('Seu E-mail')"></at-input>

            <div class="planOrFeeDetails_recoverPassword_form_actions">
                <at-button @click.prevent="recover" type="primary" size="large" :disabled="this.$v.email.$invalid || email == ''">Recuperar senha</at-button>
                <p class="planOrFeeDetails_register_form_actions_recoverPassword" @click.prevent="$emit('recover-password:back', {status: false});">Voltar</p>
            </div>
        </div>
    </div>-->
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { email } from 'vuelidate/lib/validators';
    
export default {
    name: "RecoverPassword",
    data(){
        return {
            email: ''
        }
    },
    validations: {
        email: { email }  
    },
    computed: {
        ...mapState([
           'endpointOffice' 
        ]),
        ...mapGetters([
            'translateWord'
        ])
    },
    methods: {
        ...mapMutations([
            'enabledFullScreenLoading' 
        ]),
        async recover(){
            this.enabledFullScreenLoading({
                status: true,
                text: this.translateWord('Verificando e-mail para recuperação da senha...')
            });
            
            let data = (await this.$httpOffice.post(`${this.endpointOffice.RECOVER_PASSWORD}?email=${this.email}`)).data;

            if(!data.success){
                this.$Notify.error(this.translateWord('Ocorreu um erro ao recuperar a senha, favor tente mais tarde!'));
                this.enabledFullScreenLoading({status: false});

                this.$emit('recover-password:back', {status: false});
                return;
            }

            this.enabledFullScreenLoading({status: false});
            this.$Notify.success(`Sua senha foi enviada para o e-mail: ${this.email}`);
            this.$emit('recover-password:back', {status: true, email: this.email});
        }
    }
}
</script>

<style scoped>

</style>