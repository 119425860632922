<template>
    <div>
        <div v-if="condition == 'show'">
            <div v-show="isActiveAttribute">
                <slot></slot>
            </div>
        </div>
        
        <div v-else>
            <div v-if="isActiveAttribute">
                <slot></slot>
            </div>
        </div>
    </div>
    
</template>

<script>
    export default {
        name: "step",
        props: {
            name: {
                required: true,
                type: String
            },
            active: {
                type: Boolean
            },
            finish: {
                type: Boolean
            },
            condition: {
                type: String,
                default: 'if'
            },
            icon: {
                type: String
            }
        },
        inject: [
            'insertStep'
        ],
        data(){
            return {
                isActiveAttribute: false,
                isFinishAttribute: false
            }
        },
        created(){
              this.insertStep(this);
        },
        mounted(){
            this.isFinishAttribute = this.finish;
            this.isActiveAttribute = this.active;
        },
        methods:{
            isFinish(Status){
                this.isFinishAttribute = Status;
            },
            isActive(Status){
                this.isActiveAttribute = Status;
            }
        }
    }
</script>

<style scoped>

</style>