import Vue from 'vue'
import atUi from 'at-ui'
import 'at-ui-style'
import axios from 'axios';
import VueTheMask from 'vue-the-mask';
import App from './App.vue'
import router from './router'
import store from './store'
import Help from './assets/js/helper';
import vuelidate from 'vuelidate'
import Clipboard from 'vue-clipboard2';
import Icons from './components/Icons';

Vue.config.productionTip = false
Vue.use(atUi);
Vue.use(VueTheMask);
Vue.use(vuelidate);

Clipboard.config.autoSetContainer = true;
Vue.use(Clipboard);

Vue.component('icon', Icons)

Vue.prototype.$http = axios.create({
    auth: {
        username: 'AccessBuilderallGatewayPro',
        password: 'E-businnes+Builderall2019!'
    },
    baseURL: Help.getEnvs().urlCheckout,
});
Vue.prototype.$httpTranslation = axios.create({
    baseURL: Help.getEnvs().urlTranslation
});
Vue.prototype.$httpOffice = axios.create({
    baseURL: Help.getEnvs().urlOffice,
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});
Vue.prototype.$httpWordpress = axios.create({
    baseURL: Help.getEnvs().urlWordpress
});
Vue.prototype.$httpCheetah = axios.create({
    baseURL: Help.getEnvs().urlCheetah
});
Vue.prototype.$httpMicroserviceDebitedBuilderall = axios.create({
    baseURL: Help.getEnvs().urlMicroserviceDebitedBuilderall,
    headers: {
        'Authorization': 'Bearer nybM15kdZQbCPu5MDmtjKqdq6rlCE5joQQlJcN50kOiMNsMazPVltlJFjy7Y',
        'Content-Type': 'application/json'
    }
});
Vue.prototype.$httpOfficeAdmin = axios.create({
    baseURL: Help.getEnvs().urlOfficeAdmin
});

Vue.prototype.$logs = axios.create({
    baseURL: `${Help.getEnvs().urlLogs}/logger`,
    headers: {
        'Content-Type': 'application/json'
    },
    transformRequest: [
        (data, headers) => {
            let newData = {
                application: 'BUILDERALL_CHECKOUT',
                env: Help.getEnvs().env == 'prod' ? 'PROD' : 'SANDBOX',
                message: data[0] != undefined ? data[0] : null,
                data: data[1] != undefined ? data[1] : null,
                type: data[2] != undefined ? data[2] : 'WARNING',
                ip: localeUser['ip']
            }

            return JSON.stringify(newData);
        }
    ]
});

Vue.prototype.$httpDomain = axios.create({
    baseURL: Help.getEnvs().domainRegistry
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
