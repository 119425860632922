import Helper from '../js/helper';
import Store from '../../store';

export default{

    /**
     * Pega a palavra para o idioma acessado. Caso nao exista a palavra, irar criar uma nova chave.
     * 
     * @param Palavra
     * @param ArrayReplace
     * @returns {*|string}
     */
    get(Palavra, ArrayReplace){
        let chave = Helper.transformePalavraTraducao(Palavra);
        let word = Store.state.translate[chave];

        if(word == undefined){
            return Store.dispatch('traduzirPalavra', { 
                word: Palavra 
            });
        }
        
        for(let indice in ArrayReplace){
            word = word.replace('{'+indice+'}', ArrayReplace[indice]);
        }
        
        return word;
    }
}