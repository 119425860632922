<template>
    <div class="vat">
        <h1 class="vat_header">
            <h1>{{ translateWord('VAT') }}</h1>
        </h1>

        <div class="vat_steps">
            <div v-show="stepVat.selectCountry" class="vat_steps_step vat_selectCountry">
                <at-select class="vat_selectCountry_select" filterable v-model="selectCountry" :placeholder="translateWord('Selecione seu País')">
                    <at-option v-for="country in countries" :key="country.country_code" :value="country.country_code" :label="country.company_name">
                        <span>{{ country.company_name }}</span>
                        <span style="float: right; opacity: .6;">{{ country.country_code }}</span>
                    </at-option>
                </at-select>

                <at-button class="vat_selectCountry_btn" size="large" @click.prevent="nextByCountry" type="primary" :disabled="selectCountry == ''">{{ translateWord('Próximo') }}</at-button>
            </div>

            <div v-if="!stepVat.selectCountry" class="vat_steps_container">
                <div v-if="stepVat.ifHasVat" class="vat_ifHasVat">
                    <p>{{ translateWord('{0} cobra {1}% de VAT. No caso de uma conta empresa, favor clicar em adicionar númeo de VAT. Se não for o caso, clique em finalizar.', [
                            (countries.find(country => country.country_code == selectCountry)).company_name,
                            (countries.find(country => country.country_code == selectCountry)).fee
                        ]) }}
                    </p>

                    <div class="vat_ifHasVat_actions">
                        <at-button class="vat_ifHasVat_actions_btn" @click.prevent="changeStep('addVatNumber')" type="primary" size="large">{{ translateWord('Adicionar Número de VAT') }}</at-button>
                        <at-button class="vat_ifHasVat_actions_btn" @click.prevent="changeStep('result')" type="success" size="large">{{ translateWord('Finalizar') }}</at-button>
                    </div>

                    <p class="vat_back" @click="changeStep('selectCountry')">{{ translateWord('Voltar') }}</p>
                </div>

                <div v-if="stepVat.addVatNumber" class="vat_addVatNumber">
                    <p class="vat_addVatNumber_desc">
                        {{ translateWord('Adicione abaixo o número de VAT para que não seja cobrada a taxa de {0}% ({1}).', [
                            (countries.find(country => country.country_code == selectCountry)).fee,
                            (countries.find(country => country.country_code == selectCountry)).company_name
                        ]) }}
                    </p>

                    <at-input class="vat_addVatNumber_input" v-model="vatNumber" :placeholder="translateWord('Número de VAT')"></at-input>

                    <div class="vat_addVatNumber_actions">
                        <at-button class="vat_addVatNumber_actions_btn" @click.prevent="verifyVatNumber()" type="success" size="large">{{ translateWord('Inserir') }}</at-button>
                    </div>

                    <p class="vat_back" @click="changeStep('ifHasVat')">{{ translateWord('Voltar') }}</p>
                </div>

                <div v-if="stepVat.result" class="vat_result">
                    <template v-if="vatFee.isValid">
                        <h3 class="vat_result_vatValid vat_result_vatValidNumberCompleted">{{ vatFee.vatNumberCompleted }} <i class="icon icon-check-circle"></i></h3>
                        <p class="vat_result_vatValid vat_result_vatValidCompanyName">{{ vatFee.vatCompanyName }}</p>
                    </template>

                    <template v-else-if="isExemptVat">
                        <h3 class="vat_result_emptyVat">{{ translateWord('Isento da Taxa de VAT') }}</h3>
                    </template>

                    <template v-else>
                        <h3 class="vat_result_country">{{ (countries.find(country => country.country_code == selectCountry)).company_name }} ({{selectCountry}})</h3>
                        <p class="vat_result_vatFee">{{ translateWord('Taxa de VAT') }}: <b>{{ (countries.find(country => country.country_code == selectCountry)).fee }}%</b></p>
                    </template>

                    <p class="vat_back" @click="changeStep('selectCountry')">{{ translateWord('Voltar') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';  

import Helper from '../assets/js/helper';
import PixelAnalytics from '../assets/js/PixelAnalytics'

export default {
    name: "Vat",
    data(){
        return {
            vatNumber: '',
            feeVat: '',
            countries: [],
            selectCountry: '',
            showCountrySelected: false,
            stepVat: {
                selectCountry: true,
                ifHasVat: false,
                addVatNumber: false,
                result: false
            }
        }
    },
    computed: {
        ...mapState([
            'endpointGateway',
            'endpointOffice',
            'localeUser',
            'coupon',
            'vatFee',
            'user'
        ]),
        ...mapGetters([
            'product',
            'translateWord',
            'isExemptVat',
            'isChangeVat',
            'hasCouponAndIsValid',
            'productAmount'
        ])
    },
    async created(){
        if(['dev', 'test'].includes(Helper.getEnvs().env)){
            this.vatNumber = 'EL078909371';
        }
        
        this.countries = (await this.$http.post(`${this.endpointGateway.vatGetFees}`)).data.vat;
        this.countries.push({company_name: 'Outside EU',country_code: 'OEU',fee: 0});

        /*let result = (await this.$httpOffice.post(this.endpointOffice.UPDATE_USER_CONFIG, Helper.serialize({vat_number: 'TESTE123'}))).data;

        console.log(result);*/


        //Aplicar vat se ja esta configurando na conta do usuario
        if(this.user.hasOwnProperty('vat_number') && this.user.vat_number != ''){
            this.vatNumber = this.user.vat_number;
            this.verifyVatNumber();
        }
    },
    methods: {
        ...mapMutations([
            'enabledFullScreenLoading',
            'addValueVat',
            'addVatNumberUser',
            'setStatusEmptyVat',
            'resetVat'
        ]),
        async nextByCountry(){
            if(this.selectCountry == ''){
                this.$Notify.info(this.translateWord('Voce precisa selecionar seu pais, para prosseguir o pagamento'));

                return;
            }

            this.resetVat();
            this.setStatusEmptyVat(false);
            
            //Calcular VAT apenas se o usuario clicar em finalizar no step de ifHasVat
            this.enabledFullScreenLoading({status: true, text: this.translateWord('Calculando valores de VAT para adicionar ao produto')});

            if(this.isVerifyEmptyVat()){
                this.changeStep('result');
                this.enabledFullScreenLoading({status: false});

                this.$emit('vat:is-completed', true);

                return;
            }

            await this.getFeeVat(this.selectCountry);
            
            this.applyVatInAmount();
            
            this.localeUser.country = this.selectCountry;
            
            this.enabledFullScreenLoading({status: false});

            //Removido pois é para validar igual aos outros paises da europa
            /*if(this.isVerifyNetherlands()){
                this.changeStep('result');
                this.$emit('vat:is-completed', true);
                
                return;
            }*/

            this.changeStep('ifHasVat');

            this.enabledFullScreenLoading({status: false});
        },
        async verifyVatNumber(){
            if(this.vatNumber == null || this.vatNumber == ''){
                this.$Notify.error(this.translateWord('Nenhum número de VAT foi preenchido'));
                return;
            }
            
            this.enabledFullScreenLoading({
                status: true,
                text: this.translateWord('Verificando se o número de VAT {0} é válido', [
                    this.vatNumber
                ])
            });

            let resultVat = await this.$http.post(this.endpointGateway.vatValidate, {
                vat_number: this.vatNumber
            });

            if(!resultVat.data.vat.valid){
                this.$Notify.error(this.translateWord('O número de VAT {0} é inválido!', [
                    this.vatNumber
                ]));
                
                this.enabledFullScreenLoading({status: false});
                
                return;
            }

            if(resultVat.data.vat.valid){
                this.resetVat();
                
                this.addVatNumberUser({
                    vatNumberCompleted: this.vatNumber,
                    vatNumber: resultVat.data.vat.vat_number,
                    vatCountry: resultVat.data.vat.country_code,
                    vatCompanyName: resultVat.data.vat.company_name,
                    vatCompanyAddress: resultVat.data.vat.company_address
                });

                PixelAnalytics.addDataLayer('VatIncluded');
                
                this.$Notify.success(this.translateWord('O número de VAT {0} é válido', [
                    this.vatNumber
                ]));

                this.localeUser.country = resultVat.data.vat.country_code;

                this.changeStep('result');
                this.$emit('vat:is-completed', true);
            }

            this.enabledFullScreenLoading({status: false});
        },
        changeStep(stepName){
            let stepArray = Object.entries(this.stepVat)
                .map((data) => [data[0], data[0] == stepName ? true : false]);
            
            stepName == 'result'
                ? this.$emit('vat:is-completed', true)
                : this.$emit('vat:is-completed', false);
            
            this.stepVat = Object.fromEntries(stepArray);
        },
        /**
         * Verificar se o pais selecionado é isento ao VAT
         */
        isVerifyEmptyVat(){
            /**
             * OEU = Outside EU
             * CAI = Canary Island
             * FRG = France Guiane
             */
            if(['OEU', 'CAI', 'FRG'].includes(this.selectCountry)){
                this.localeUser.country = 'OE';
                this.addValueVat({ percentage: 0 });
                this.setStatusEmptyVat(true);
                return true;
            }
            
            return false;
        },
        /**
         * Verificar se o pais selecionado é Holanda. Se for cobrar o valor fixo do vat fee da holanda
         */
        isVerifyNetherlands(){
            return this.selectCountry == 'NL' ? true : false;
        },
        applyVatInAmount(){
            this.addValueVat({
                percentage: this.feeVat,
                amount: Helper.calculateVat(this.productAmount, this.feeVat),
                amountFirstMonth: Helper.calculateVat(this.product.amountFirstMonth, this.feeVat),
                amountWithoutCoupon: Helper.calculateVat(this.product.amount, this.feeVat)
            });
        },
        async getFeeVat(Country){
            let countryParam = Country || this.localeUser.country;

            this.feeVat = (await this.$http.post(`${this.endpointGateway.vatGetFee}`, {
                country_code: countryParam
            })).data.vat.fee;

            if(countryParam == Helper.countryDutch) this.applyVatInAmount();
        }
    }
}
</script>

<style lang="scss">
    @import "../scss/mixins";

    .vat_header{
        margin: 10px auto;

        & h1{
            font-size: 0.9em;
            color: var(--gray-500);
        }
    }

    .vat_steps_container{
        padding: 40px;
        background: var(--white);
        border: 1px solid var(--gray-100);
        border-radius: 8px;
    }

    .vat_back{
        text-align: center;
        margin-top: 15px;
        font-size: 0.8em;
        color: var(--gray-500);
        cursor: pointer;
    }

    .vat_selectCountry{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .vat_selectCountry_select{
        width: 100%;
        margin-right: 20px;

        & .at-select__input{
            text-align: center;
        }
    }

    .vat_ifHasVat,
    .vat_addVatNumber{
        font-size: 0.8em;
    }
    .vat_ifHasVat_actions{
        display: flex;
        margin-top: 30px;
    }
    .vat_ifHasVat_actions_btn{
        margin-right: 10px !important;

        &:last-child{
            margin-right: 0px !important;;
        }
    }

    .vat_addVatNumber{
        text-align: center;
    }
    .vat_addVatNumber_input{
        margin-top: 20px;

        & .at-input__original{
            background: #EDEDED;
            border: 1px solid #E2E2E2;
            border-radius: 8px !important;
        }
    }
    .vat_addVatNumber_desc{
        font-weight: 500;
    }

    .vat_result{
        text-align: center;

        & h3{
            font-size: 1.8em;

            &.vat_result_vatValid{
                color: var(--green);
            }

            &.vat_result_emptyVat{
                text-transform: uppercase;
            }
        }

        p{
            color: var(--gray-500);

            &.vat_result_vatFee{
                text-transform: uppercase;
            }
        }
    }

    @include media(sm,md){
        .vat_selectCountry{
            flex-direction: column;
        }
        .vat_selectCountry_select{
            margin: 0px;
        }
        .vat_selectCountry_btn{
            margin-top: 10px !important;
            width: 100% !important;
        }

        .vat_ifHasVat_actions{
            flex-direction: column;
        }
        .vat_ifHasVat_actions_btn{
            width: 100% !important;
            margin-bottom: 10px !important;
        }

        .vat_addVatNumber_actions_btn{
            width: 100% !important;
        }
    }
</style>