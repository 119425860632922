<template>
    <div class="steps">
        <!--<div class="checkout_headResponsive">
            <span @click="prevStepCurrentComponent" v-if="backEnabled" class="checkout_headResponsive_back">
                <b><i class="icon icon-arrow-left"></i> {{ nameBack }}</b>
            </span>

            <div class="checkout_headResponsive_logo">
                <img src="../../assets/builderall-logo-name.png" alt="Builderall" title="Builderall">
            </div>

            <div class="checkout_headResponsive_user">
                <div class="checkout_headResponsive_user_info">
                    <p>{{ nameIndicated }}</p>
                    <p><b>{{ userFather.name }}</b></p>
                </div>

                <img class="checkout_headResponsive_user_img" :src="userFather.avatar" :alt="userFather.name" :title="userFather.name">
            </div>
        </div>
        
        <div class="steps_header">
            <div class="steps_header_logo">
                <img src="../../assets/builderall-logo-name.png" alt="Builderall Checkout" title="Builderall Checkout">
            </div>
            
            <div class="steps_header_progressContainer">
                <div @click="changeStepNavbar(step.name)" class="steps_progress_item" :class="[{active: step.isActiveAttribute || step.isFinishAttribute}, {green: step.color == 'green'}, {red: step.color == 'red'}]" v-for="(step, index) in steps">
                    <p class="steps_progress_item_name">
                        {{ step.name }}
                    </p>
                </div>
            </div>
        </div>
        
        <div class="steps_headResponsive">
            <div @click="changeStepNavbar(step.name)" v-html="step.icon" class="steps_headResponsive_item" :class="[{active: step.isActiveAttribute || step.isFinishAttribute}, {green: step.color == 'green'}, {red: step.color == 'red'}]" v-for="(step, index) in steps">
            </div>
        </div> -->

        <div class="steps_header">
            <img class="steps_header_logo" src="@/assets/builderall-logo-name.svg" alt="Builderall" title="Builderall">

            <div class="steps_header_progress">
                <div @click="changeStepNavbar(step.name)"
                     v-for="(step, index) in steps"
                     class="steps_header_progress_item"
                     :class="[
                        {finish: step.isFinishAttribute},
                        {active: step.isActiveAttribute},
                    ]">
                        <div class="steps_header_progress_item_circle">
                            <div class="steps_header_progress_item_circle_ball">
                                <i class="steps_header_progress_item_circle_ball_icon icon icon-check"></i>
                            </div>
                        </div>

                        <!--<p class="steps_header_progress_item_name">{{ step.name }}</p>
                        <icon v-if="(totalStep - 1) != index" class="steps_header_progress_item_icon" :name="step.icon" />-->
                </div>
            </div>
        </div>

        <div class="steps_content">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "steps",
    props: {
        userFather: {
            type: Object
        },
        nameIndicated: {
            type: String
        },
        nameBack: {
            type: String
        }
    },
    data(){
        return {
            stepCurrentName: '',
            stepCurrentIndice: 0,
            totalStep: 0,
            steps: []
        }  
    },
    provide(){
        return {
            insertStep: (step) => this.steps.push(step),
            nextStep: () => {
                let stepIndiceCurrent = this.stepCurrentIndice;
                let stepIndiceNext = ++this.stepCurrentIndice;

                this.steps[stepIndiceCurrent].isFinish(true);
                this.steps[stepIndiceCurrent].isActive(false);

                this.steps[stepIndiceNext].isActive(true);

                this.$emit('step-name', this.steps[stepIndiceNext].name);
            },
            prevStep: () => {
                let stepIndiceCurrent = this.stepCurrentIndice;
                let stepIndiceNext = --this.stepCurrentIndice;
                
                this.steps[stepIndiceCurrent].isFinish(false);
                this.steps[stepIndiceCurrent].isActive(false);

                this.steps[stepIndiceNext].isActive(true);
                this.steps[stepIndiceNext].isFinish(false);

                this.$emit('step-name', this.steps[stepIndiceNext].name);
            }
        }
    },
    mounted() {
        this.steps.forEach((step, index) => {
            if(step.active) {                
                this.stepCurrentName = step.name;
                this.stepCurrentIndice = index;
            }
        });
        
        this.totalStep = this.steps.length;
    },
    methods:{
        changeStepNavbar(name){

        },
        prevStepCurrentComponent(){
            let stepIndiceCurrent = this.stepCurrentIndice;
            let stepIndiceNext = --this.stepCurrentIndice;

            this.steps[stepIndiceCurrent].isFinish(false);
            this.steps[stepIndiceCurrent].isActive(false);

            this.steps[stepIndiceNext].isActive(true);

            this.$emit('step-name', this.steps[stepIndiceNext].name);
        }
    }
}
</script>

<style lang="scss">
    @import "../../scss/mixins";

    .steps{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .steps_header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .steps_content{
        width: 100%;
        margin-top: 15px;
    }
    .steps_header_logo{
        width: 150px;
    }
    .steps_header_progress{
        display: flex;
        margin: 20px auto;
        text-transform: uppercase;
        margin-top: 40px;
    }

    .steps_header_progress_item_circle{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 1px solid #D9D9D9;
        background: #ffffff;
        border-radius: 50%;
        left: 35%;
    }
    .steps_header_progress_item_circle_ball{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 16px;
        height: 16px;
        background: #DBDBDB;
        border-radius: 50%;
    }
    .steps_header_progress_item_circle_ball_icon{
        display: none;
        color: #ffffff;
        font-size: 0.7em;
    }
    .steps_header_progress_item{
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 600;
        width: 100px;
        height: 2px;
        background: var(--gray-100);

        &.finish{
            & .steps_header_progress_item_circle_ball{
                background: var(--blue);
            }
            & .steps_header_progress_item_circle_ball_icon{
                display: block;
            }
        }
        &.active{
            & .steps_header_progress_item_circle{
                width: 28px;
                height: 28px;
            }

            & .steps_header_progress_item_circle_ball{
                width: 20px;
                height: 20px;
                background: var(--blue);
            }
        }

        &:first-child{
            & .steps_header_progress_item_circle{
                right: inherit;
                left: 0px;
            }
        }

        &:last-child{
            & .steps_header_progress_item_circle{
                right: 0px;
                left: inherit;
            }
        }
    }
    .steps_header_progress_item_name{
        font-size: 0.8em;
    }
    .steps_header_progress_item_icon{
        font-size: 0.6em;
        margin-left: 10px;
        color: #000;
    }

    @include media(sm,md,lg){
        .steps_header_logo{
            width: 100px;
        }
    }
</style>