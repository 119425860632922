<template>
    <div class="currencyNotAvailable">
        Moeda não disponivel
    </div>
</template>

<script>
import Helper from '../assets/js/helper';
import Translate from '../assets/js/translate';

export default {
    name: "CurrencyNotAvailable",
    props: {
        currency: {
            type: String,
            required: true
        },
        planOrFee: {
            type: String,
            required: true
        }
    },
    data(){
        return {
            url_office_support: Helper.getUrlOffice(`office/support`),
            message: "No momento não esta disponivel pagamentos em <b>{0}</b>, devido a este motivo você nao pode comprar <b>{1}</b>. <br>Para mais informações entre em contato com nossa equipe de suporte."
        }
    },
    methods:{
        translate(word, arrayReplace){
            return Translate.get(word, arrayReplace);
        }  
    },
    mounted(){
        switch(this.currency){
            case 'BRL': this.currency = 'Real'; break;
            case 'USD': this.currency = 'Dólar'; break;
            case 'EUR': this.currency = 'Euro'; break;
        }
    }
}
</script>