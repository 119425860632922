<template>
    <div class="plans" v-if="loadTranslation">
        <div class="container plans_login">
            <div class="content">
                <div class="plans_login_item plans_login_socialMedia">
                    <a :href="links.facebook" class="icon icon-facebook"></a>
                    <a :href="links.instagram" class="icon icon-instagram"></a>
                </div>

                <a :href="`${links.getSupport}${allParams()}`" class="plans_login_item plans_login_support">{{ translateWord('Suporte') }}</a>

                <a :href="`${links.login}${allParams()}`" class="plans_login_item plans_login_login">{{ translateWord('Login') }} <i class="icon icon-arrow-up-right"></i></a>
            </div>
        </div>

        <div class="container plans_menu">
            <div class="content">
                <a :href="`${links.home}${allParams()}`" class="plans_menu_logo">
                    <img :src="require('../assets/builderall-marketing-suite.svg')" alt="Builderall" title="Builderall">
                </a>

                <div class="plans_menu_menuList">
                    <a class="plans_menu_menuList_link" :href="`${links.home}${allParams()}`">{{ translateWord('Home') }}</a>
                    <a class="plans_menu_menuList_link active" :href="`${allParams()}`">{{ translateWord('Planos') }}</a>
                    <a class="plans_menu_menuList_link" :href="`${links.ourTools}${allParams()}`">{{ translateWord('Nossas Ferramentas') }}</a>
                    <a class="plans_menu_menuList_link" :href="`${links.successStories}${allParams()}`">{{ translateWord('Histórias de Sucesso') }}</a>
                </div>

                <div class="plans_menu_actions">
                    <a href="" class="btn2 btn_blue">{{ translateWord('Comece Agora') }}</a>
                    <span @click="viewMenuResponsive = !viewMenuResponsive" class="menuResponsive icon icon-align-justify"></span>
                </div>
            </div>

            <div v-if="viewMenuResponsive" class="plans_menu_menuListResponsive">
                <a class="plans_menu_menuList_link" :href="`${links.home}${allParams()}`">{{ translateWord('Home') }}</a>
                <a class="plans_menu_menuList_link active" :href="`${allParams()}`">{{ translateWord('Planos') }}</a>
                <a class="plans_menu_menuList_link" :href="`${links.ourTools}${allParams()}`">{{ translateWord('Nossas Ferramentas') }}</a>
                <a class="plans_menu_menuList_link" :href="`${links.successStories}${allParams()}`">{{ translateWord('Histórias de Sucesso') }}</a>
            </div>
        </div>

        <div class="container containerAdapter plans_planType">
            <div class="content">
                <div class="plans_planType_left">
                    <h1 class="plans_planType_left_title">{{ translateWord('Tudo que você precisa em um pagamento mensal') }}<span>.</span></h1>
                </div>

                <div class="plans_planType_right">
                    <div class="plans_planType_right_content">
                        <p v-html="translateWord('Escolha seu <b>plano mensal</b>, acesse seu escritório e <b>comece a construir seu negócio.</b>')" class="plans_planType_right_desc"></p>

                        <div class="plans_planType_right_actions">
                            <p @click="changePlanType('normal')" class="plans_planType_right_actions_action btn2 btn_blue">{{ translateWord('Planos Builderall') }}</p>
                            <p @click="changePlanType('wp')" class="plans_planType_right_actions_action btn3">{{ translateWord('Planos WordPress') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container containerAdapter plans_details">
            <div class="content">
                <Loading v-if="loading.plans" class="loading"></Loading>

                <template v-if="planTypeView == 'normal' && !loading.plans">
                    <div v-for="plan in plans" v-if="plan.slug.search('wp') !== 0" class="plans_details_item">
                        <p v-if="plan.tag" class="plans_details_item_tag">{{ plan.tag }}</p>

                        <h3 class="plans_details_item_title">{{ plan.title }}</h3>
                        <p v-if="plan.subTitle" class="plans_details_item_subTitle">{{ plan.subTitle }}</p>

                        <div class="plans_details_item_price">
                            <span class="plans_details_item_price_symbol">{{ plan.symbol }}</span>
                            <span class="plans_details_item_price_amount" :class="{plans_details_item_price_amountSmallsize: parseInt(plan.price).toString().length >= 6}">
                            <strong>{{ amountSplit(plan.price) }}</strong><span class="plans_details_item_price_amount_cents"> /mo</span>
                        </span>

                            <p class="plans_details_item_price_desc">{{ plan.description }}</p>
                        </div>

                        <div class="plans_details_item_desc">
                            <p v-for="info in plan.info" class="plans_details_item_desc_item">
                                <img class="plans_details_item_desc_item_img" :src="require('../assets/imagem/verify.svg')" alt="">
                                {{ info }}
                            </p>

                        </div>

                        <a :href="plan.link" class="btn2 btn_blue plans_details_item_action">{{ translateWord('ASSINAR AGORA') }}</a>

                        <div class="plans_details_item_details">
                            <p class="plans_details_item_details_title">{{ translateWord('Ferramentas Inclusas no Plano:') }}</p>
                            <p class="plans_details_item_details_desc">{{ plan.includedName }}</p>

                            <img class="plans_details_item_details_image" :src="plan.imageTools" alt="Tools">
                        </div>
                    </div>
                </template>

                <template v-if="planTypeView == 'wp' && !loading.plans">
                    <div v-for="plan in plans" v-if="plan.slug.search('wp') !== -1" class="plans_details_item">
                        <p v-if="plan.tag" class="plans_details_item_tag">{{ plan.tag }}</p>

                        <h3 class="plans_details_item_title">{{ plan.title }}</h3>
                        <p v-if="plan.subTitle" class="plans_details_item_subTitle">{{ plan.subTitle }}</p>

                        <div class="plans_details_item_price">
                            <span class="plans_details_item_price_symbol">{{ plan.symbol }}</span>
                            <span class="plans_details_item_price_amount" :class="{plans_details_item_price_amountSmallsize: parseInt(plan.price).toString().length >= 6}">
                            <strong>{{ amountSplit(plan.price) }}</strong><span class="plans_details_item_price_amount_cents"> /mo</span>
                        </span>

                            <p class="plans_details_item_price_desc">{{ plan.description }}</p>
                        </div>

                        <div class="plans_details_item_desc">
                            <p v-for="info in plan.info" class="plans_details_item_desc_item">
                                <img class="plans_details_item_desc_item_img" :src="require('../assets/imagem/verify.svg')" alt="">
                                {{ info }}
                            </p>
                        </div>

                        <a :href="plan.link" class="btn2 btn_blue plans_details_item_action">{{ translateWord('ASSINAR AGORA') }}</a>

                        <div class="plans_details_item_details">
                            <p class="plans_details_item_details_title">{{ translateWord('Ferramentas Inclusas no Plano:') }}</p>
                            <p class="plans_details_item_details_desc">{{ plan.includedName }}</p>

                            <img class="plans_details_item_details_image" :src="plan.imageTools" alt="Tools">
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="container plans_security">
            <div class="content">
                <div class="plans_security_item">
                    <img class="plans_security_item_image" :src="require('../assets/imagem/pay-card.png')" alt="">

                    <div class="plans_security_item_info">
                        <p class="plans_security_item_info_text">{{ translateWord('ACEITAMOS TODAS AS BANDEIRAS') }}</p>

                        <img :src="require('../assets/imagem/brands-union.png')" alt="" class="plans_security_item_info_image">
                    </div>
                </div>

                <div class="plans_security_item">
                    <img class="plans_security_item_image" :src="require('../assets/imagem/ssl.png')" alt="">

                    <div class="plans_security_item_info">
                        <p class="plans_security_item_info_text">{{ translateWord('SSL PAGAMENTO SEGURO') }}</p>
                        <p class="plans_security_item_info_textSub">{{ translateWord('Toda a informação é criptografada e transmitida sem risco usando um protocolo de camada de segurança. Você pode confiar em nós!.') }}</p>
                    </div>
                </div>

                <div class="plans_security_item">
                    <img class="plans_security_item_image" :src="require('../assets/imagem/money.png')" alt="">

                    <div class="plans_security_item_info">
                        <p class="plans_security_item_info_text">{{ translateWord('SATISFAÇÃO GARANTIDA') }}</p>
                        <p class="plans_security_item_info_textSub">{{ translateWord('Você pode cancelar quando desejar e dentro de 7 dias pode solicitar o reembolso do seu primeiro pagamento.') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="container plans_toolsAndFeature">
            <div class="content">
                <h1 class="plans_toolsAndFeature_title">{{ translateWord('Ferramentas & funcionalidades') }}<span>.</span></h1>

                <div class="plans_toolsAndFeature_table">

                    <div class="plans_toolsAndFeature_table_header">
                        <p class="plans_toolsAndFeature_table_text plans_toolsAndFeature_table_textStart">{{ translateWord('Recursos') }}</p>

                        <div class="plans_toolsAndFeature_table_header_check">
                            <p class="plans_toolsAndFeature_table_header_check_text">STARTER</p>
                            <p class="plans_toolsAndFeature_table_header_check_text">MARKETER</p>
                            <p class="plans_toolsAndFeature_table_header_check_text">PREMIUM</p>
                            <p class="plans_toolsAndFeature_table_header_check_text">FUNNEL CLUB</p>
                        </div>
                    </div>

                    <Loading v-if="loading.features" class="loading"></Loading>

                    <div v-for="feature in features" class="plans_toolsAndFeature_table_item">
                        <div @click="feature.showFeatureDetails = !feature.showFeatureDetails" class="plans_toolsAndFeature_table_item_content">
                            <span class="icon icon-arrow-down plans_toolsAndFeature_table_item_dropdown"></span>

                            <p class="plans_toolsAndFeature_table_item_text">{{ feature.name }}</p>

                            <div class="plans_toolsAndFeature_table_item_check">
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="feature.starter" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="feature.marketer" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="feature.premium" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="feature.funnelClub" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                            </div>
                        </div>

                        <div v-for="featureDetails in feature.featureDetails" v-if="feature.showFeatureDetails" class="plans_toolsAndFeature_table_item_content plans_toolsAndFeature_table_item_contentSub">
                            <p class="plans_toolsAndFeature_table_item_text"><i class="icon icon-chevron-right"></i>{{ featureDetails.name }}</p>

                            <div class="plans_toolsAndFeature_table_item_check">
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="featureDetails.starter" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="featureDetails.marketer" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="featureDetails.premium" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                                <p class="plans_toolsAndFeature_table_item_check_check">
                                    <img v-if="featureDetails.funnelClub" :src="require('../assets/imagem/verify-2.svg')" alt="">
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="container plans_questions">
            <div class="content">

                <div class="plans_questions_container">
                    <h1 class="plans_questions_title">{{ translateWord('Perguntas frequentes') }}<span>.</span></h1>

                    <div class="plans_questions_questions">
                        <div v-for="question in questions" class="plans_questions_questions_question">
                            <p @click="question.visible = !question.visible" class="plans_questions_questions_question_text">{{ question.text }}</p>
                            <p v-if="question.visible" class="plans_questions_questions_question_reply">{{ question.reply }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="container plans_footer">
            <div class="content">

                <div class="plans_footer_block plans_footer_blockAbout">
                    <p class="plans_footer_blockAbout_title">
                        {{ translateWord('SOBRE BUILDERALL®') }}
                    </p>

                    <p class="plans_footer_blockAbout_desc">
                        {{ translateWord('Builderall® é uma marca registrada da Cloud Builder LLC. Todos os elementos, imagens e conteúdos deste site são © 2023 Cloud Builder LLC.') }}
                    </p>
                </div>

                <div class="plans_footer_block plans_footer_blockMenu1">
                    <li>
                        <a :href="`${links.home}${allParams()}`">{{ translateWord('Home') }}</a>
                    </li>

                    <li>
                        <a :href="`${allParams()}`">{{ translateWord('Planos') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.about}${allParams()}`">{{ translateWord('Sobre Nós') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.successStories}${allParams()}`">{{ translateWord('Histórias de Sucesso') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.affiliate}${allParams()}`">{{ translateWord('Afiliados') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.login}${allParams()}`">{{ translateWord('Login') }}</a>
                    </li>

                </div>

                <div class="plans_footer_block plans_footer_blockMenu2">
                    <li>
                        <a :href="`${links.ourTools}${allParams()}`">{{ translateWord('Nossas Ferramentas') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.elements}${allParams()}`">{{ translateWord('Elementos') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.partners}${allParams()}`">{{ translateWord('Parceiros') }}</a>
                    </li>
                </div>

                <div class="plans_footer_block plans_footer_blockMenu3">
                    <li>
                        <a :href="`${links.legalInformation}${allParams()}`">{{ translateWord('Informações Legais') }}</a>
                    </li>

                    <li>
                        <a :href="`${links.termsOfUse}${allParams()}`">{{ translateWord('Termos de Uso') }}</a>
                    </li>
                </div>

                <div class="plans_footer_block plans_footer_blockMidiaSocial">
                    <div class="plans_footer_blockMidiaSocial_icons">
                        <a :href="links.facebook" class="icon icon-facebook"></a>
                        <a :href="links.instagram" class="icon icon-instagram"></a>
                    </div>

                    <p v-html="translateWord('Nós gostariamos de ouvir de você! Nos envie um e-mail para <b>{0}</b> com quaisquer perguntas ou comentários.', ['contato@builderall.com'])" class="plans_footer_blockMidiaSocial_desc"></p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import axios from 'axios';
import Helper from '../assets/js/helper';
import Loading from '../components/Loading';

export default {
    name: "PlansLegacy",
    data(){
        return {
            defaultCountryPrice: 'us',
            planTypeView: 'normal',
            loadTranslation: false,
            plans: [],
            features: [],
            questions: [],
            countryUser: null,
            plansChargeCountry: [],
            countryPrices: [],
            loading: {
                plans: true,
                features: true
            },
            viewMenuResponsive: false,
            links: {}
        }
    },
    async created(){
        await this.verifyTranslation();

        this.links = this.linksPage('plans');

        this.plans = [
            {
                title: 'Starter',
                price: 1790,
                symbol: '$',
                includedName: 'Builderall Builder',
                slug: 'starter-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/starter-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['1']),
                    this.translateWord('{0} Subdominíos', ['3']),
                    this.translateWord('{0} Inscritos', ['2,000']),
                    this.translateWord('{0} Espaço em Disco', ['2GB'])
                ],
                imageTools: require('../assets/imagem/tools_1.png')
            },
            {
                title: 'Marketer',
                price: 7790,
                symbol: '$',
                includedName: 'Builderall Builder + Mailingboss',
                slug: 'marketer-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/marketer-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['3']),
                    this.translateWord('{0} Subdominíos', ['5']),
                    this.translateWord('{0} Inscritos', ['5,000']),
                    this.translateWord('{0} Espaço em Disco', ['5GB'])
                ],
                imageTools: require('../assets/imagem/tools_1.png')
            },
            {
                title: 'Premium',
                subTitle: 'Marketing Suite',
                price: 8790,
                symbol: '$',
                tag: '🤩 BEST VALUE 🤩',
                includedName: 'All Tools Included',
                slug: 'premium-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/premium-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['15']),
                    this.translateWord('Subdomínios Ilimitados'),
                    this.translateWord('{0} Inscritos', ['10,000']),
                    this.translateWord('{0} Espaço em Disco', ['10GB'])
                ],
                imageTools: require('../assets/imagem/tools_2.png')
            },
            {
                title: 'Funnel Club',
                subTitle: 'Marketing Suite',
                description: '+ $199 - First Payment',
                price: 8790,
                symbol: '$',
                includedName: 'All Tools + Templates + Funnels',
                slug: 'funnel-club-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/funnel-club-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['15']),
                    this.translateWord('Subdomínios Ilimitados'),
                    this.translateWord('{0} Inscritos', ['25,000']),
                    this.translateWord('{0} Espaço em Disco', ['10GB'])
                ],
                imageTools: require('../assets/imagem/tools_3.png')
            },
            {
                title: 'WP Starter',
                price: 2790,
                symbol: '$',
                includedName: 'Builderall Builder',
                slug: 'wp-starter-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/wp-starter-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['1']),
                    this.translateWord('{0} Subdominíos', ['3']),
                    this.translateWord('{0} Inscritos', ['2,000']),
                    this.translateWord('{0} Espaço em Disco', ['2GB'])
                ],
                imageTools: require('../assets/imagem/tools_1.png')
            },
            {
                title: 'WP Marketer',
                price: 8790,
                symbol: '$',
                includedName: 'Builderall Builder + Mailingboss',
                slug: 'wp-marketer-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/wp-marketer-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['3']),
                    this.translateWord('{0} Subdominíos', ['5']),
                    this.translateWord('{0} Inscritos', ['5,000']),
                    this.translateWord('{0} Espaço em Disco', ['5GB'])
                ],
                imageTools: require('../assets/imagem/tools_1.png')
            },
            {
                title: 'WP Premium',
                subTitle: 'Marketing Suite',
                price: 9790,
                symbol: '$',
                tag: '🤩 BEST VALUE 🤩',
                includedName: 'All Tools Included',
                slug: 'wp-premium-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/wp-premium-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['15']),
                    this.translateWord('Subdomínios Ilimitados'),
                    this.translateWord('{0} Inscritos', ['10,000']),
                    this.translateWord('{0} Espaço em Disco', ['10GB'])
                ],
                imageTools: require('../assets/imagem/tools_2.png')
            },
            {
                title: 'WP Funnel Club',
                subTitle: 'Marketing Suite',
                description: '+ $199 - First Payment',
                price: 9790,
                symbol: '$',
                includedName: 'All Tools + Templates + Funnels',
                slug: 'wp-funnel-club-plan',
                link: `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}/payment/wp-funnel-club-plan${this.allParams()}`,
                info: [
                    this.translateWord('{0} Domínio', ['15']),
                    this.translateWord('Subdomínios Ilimitados'),
                    this.translateWord('{0} Inscritos', ['25,000']),
                    this.translateWord('{0} Espaço em Disco', ['10GB'])
                ],
                imageTools: require('../assets/imagem/tools_3.png')
            }
        ];

        await this.listCountryPrice();

        this.plans.map(plan => {
            let planCharge = this.countryPrices.find(planCharge => planCharge.plan.slug == plan.slug);

            if(planCharge == undefined) return;

            plan.price = parseFloat(planCharge.currency.value) * 100;
            plan.symbol = planCharge.currency.symbol;

            if(['funnel-club-plan', 'wp-funnel-club-plan'].includes(plan.slug)){
                plan.description = this.translateWord('{0} - Primeiro pagamento', [`${plan.symbol}${planCharge.plan.value_first_month}`])
            }

            return plan;
        });

        this.loading.plans = false;

        this.features = [
            {
                name: this.translateWord('Recursos da Conta'),
                starter: true,
                marketer: true,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Gerenciador de DNS'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Gerenciador de E-mail Personalizado'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Artigos na base de conhecimento & vídeos de treinamento'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Suporte via Ticket & Chat'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Comunidade no Facebook'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Builderall Builder'),
                starter: true,
                marketer: true,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Conectar Domínio ou Subdomínio'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Otimizador de SEO para Páginas de Websites'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Configurações de Site e Script'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Certificado SSL'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Cabeçalhos & Rodapés Globais'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Popups Inteligentes'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Temporizadores de contagem regressiva, evergreen e escassez'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Teste A/B'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sistema de E-commerce'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Bumpsells, upsells e downsells'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sistema de Dropshipping'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sistema de Checkout'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Áreas de membros'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sistema de Blogs'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sistema de Afiliados'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sistema de Leilão'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Design Mobile Responsivo'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Transferência de Websites e Funis'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Configurações de GDPR'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sitemap'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Servidores CDN Rápidos'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                ]
            },
            {
                name: this.translateWord('MailingBoss'),
                starter: true,
                marketer: true,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Customização & Personalização de E-mail'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Automação de E-mail Marketing em Drag & Drop'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Construtor de E-mail Responsivo'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Temporizadores de Escassez de E-mail'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Modelos de Newsletters'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Webhooks de Entrada/Saida'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Prêmios & concursos virais'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integrações com SMS e WhatsApp'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Booking & Construtor de Calendários'),
                starter: true,
                marketer: true,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Vários tipos de Calendário'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recursos de agendamento pago'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Agendamento de grupo personalizado'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Bloquear datas indisponíveis'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Formulários de registro personalizados'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Lembretes e confirmações por E-mail, SMS e WhatsApp'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integração com Google Agenda, Google Meet e Zoom'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Capacidade multilíngue'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: true,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Envio de SMS'),
                starter: false,
                marketer: true,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Integra-se com Twilio'),
                        starter: false,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Automação de WhatsApp & Launch Management Builder'),
                starter: false,
                marketer: true,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Automatize suas mensagens do WhatsApp'),
                        starter: false,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Gerenciamento de múltiplos grupos'),
                        starter: false,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Automatize e gerencie o lançamento de seu produto'),
                        starter: false,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Automação de Telegram'),
                starter: false,
                marketer: true,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Automatize suas mensagens de Telegram'),
                        starter: false,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Mensagens condicionais e cronometradas'),
                        starter: false,
                        marketer: true,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Elearning/Construtor de Cursos'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Cursos de Qualidade Profissional'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Temas Simples ou Modernos'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Acesso de Login gratuito ou Pago'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Vários formatos de conteúdo'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Liberação gradual de lições'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Amigável com conteúdo em HTML e iFrame'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recursos de avaliação e teste final'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recursos de comunicação do instrutor'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integração por Webhook'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Construtor de Webinar Livestream/Evergreen'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Transmissão ao vivo para Facebook, Youtube e RTMP'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Webinar Evergreen'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sala de reuniões para ate 20 participantes'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Grave seu Webinar'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recursos de quadro branco'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sala de Espera'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Audiência fantasma'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Botões de chamada para ação cronometrada ou manual'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recursos de administração/moderação'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Construtor de Quiz'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Vários tipos de questionário'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Vários formatos de perguntas'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recurso de compartilhamento de questionário'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Páginas de resultados personalizadas'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Construtor de Revista Digital'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Versões online ou PDF'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recurso de virada de página simulada online'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Amigável com código HTML e iFrame'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integração de vídeo'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Modelos de Revista'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Video Hosting & Manager'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Armazene vários formatos de vídeo'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Carregue vídeos do computador ou do Google Drive'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Defina imagem de capa pré-carregada, para vídeo pausado e finalizado'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Personalize controles de vídeo'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Link direto e código incorporação'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recursos de tag de vídeo, transparência e wrapper'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Chat Builder'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Caixa de bate-papo simples para o seu site'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Bate-papo público ou privado'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Messenger/Instagram Chatbot'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Integração com Facebook Messenger e Integração'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Ferramentas de Automação e Crescimento'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Recursos de assinatura'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Agende Mensagens'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Salve e compartilhar chatbots'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Website Charbot'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Ferramenta autônoma de chatbot'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Ferramenta de automação e assinatura'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integração por Webhook'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Construtor de Help Desk Profissional'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Recursos de bate-papo em tempo real & geração de tickets'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Configuração de departamento e atendente'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Sistema de login do usuário com rastreamento de tickets'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Login de atendente e chat/sistema de gerenciamento de tickets'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Ferramentas de Análise e gestão'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Gestor de Projetos Worckie'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Gerenciamento de Projetos e Tarefas'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Funções do usuário'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Notificações de alteração de tarefa'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Chat em tempo real e bate-papo em grupo'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Importe projetos do Trello'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Sistema Integrado de CRM'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Rastreie e gerencie as atividades dos visitantes em sites'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Rastreie e gerencie leads'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Builderall Builder WordPress Plugin'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Construtor de página e postagens do WordPress em Drag-and-Drop'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Integra-se com várias ferramentas Builderall'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
            {
                name: this.translateWord('Team Access'),
                starter: false,
                marketer: false,
                premium: true,
                funnelClub: true,
                showFeatureDetails: false,
                featureDetails: [
                    {
                        name: this.translateWord('Configure o acesso dos membros da equipe nas ferramentas disponíveis'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Login personalizado e permissões de conta'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    },
                    {
                        name: this.translateWord('Templates prontos para Website, Chatbots e Funis'),
                        starter: false,
                        marketer: false,
                        premium: true,
                        funnelClub: true
                    }
                ]
            },
        ];

        this.questions = [
            {
                text: this.translateWord('Existe a possibilidade de adicionar produtos ou fazer upgrades?'),
                reply: this.translateWord('Sim! Nós temos opções adicionais e upgrades disponíveis exclusivos para potencializar seu negócio.'),
                visible: false
            },
            {
                text: this.translateWord('É possível importar meu conteúdo para a plataforma?'),
                reply: this.translateWord('Como a nossa plataforma possuí uma arquitetura exclusiva e única para maximizar performance e carregamento de nossos websites, arquivos externos não são compatíveis com o nosso sistema. Para nossos novos membros que estão migrando para a Builderall, disponibilizamos diversos templates para criar seu website com facilidade.'),
                visible: false
            },
            {
                text: this.translateWord('No plano premium, eu vou receber 15 domínios gratuítos?'),
                reply: this.translateWord('Essa é a cota de quantos domínios que você pode conectar. Caso tenha necessidade de adquirir mais domínios, favor nos contactar.'),
                visible: false
            },
            {
                text: this.translateWord('Quando posso fazer um upgrade do meu plano?'),
                reply: this.translateWord('Você pode realizar um upgrade do seu plano a qualquer momento, basta contactar nosso time de suporte através do chat online ou via ticket!'),
                visible: false
            },
            {
                text: this.translateWord('É possível personalizar meu plano?'),
                reply: this.translateWord('No momento não é possível mas você pode acessar todas as ferramentas ao adquirir o plano Premium.'),
                visible: false
            }
        ]

        this.loading.features = false;
    },
    computed: {
        ...mapState([
            'localeUser',
            'endpointOffice'
        ]),
        ...mapGetters([
            'translateWord',
            'getAllParamsUrl',
            'detectReferrer',
            'linksPage'
        ])
    },
    methods: {
        ...mapActions([
            'getTranslation',
            'getCountryPrices'
        ]),
        ...mapMutations([
            'enabledFullScreenLoading'
        ]),
        async listCountryPrice(){
            this.countryPrices = await this.getCountryPrices(this.$route.query['country'] || null);

            for await(let plan of this.plans){
                let planCharge = this.countryPrices.find(planCharge => planCharge.plan.slug == plan.slug);

                if(planCharge == undefined){
                    this.countryPrices = await this.getCountryPrices(this.defaultCountryPrice);
                    return;
                }
            }
        },
        allParams(){
            let params = this.getAllParamsUrl(false);

            //Pegar aid pelo #aid quando houver
            if(Helper.getParamHash('aid')){
                params = `${params}#aid=${Helper.getParamHash('aid')}`;
            }

            return params;
        },
        amountSplit(amount, isDecimal){
            let amountFormat = (amount / 100).toFixed(2);

            return isDecimal ? amountFormat.split('.')[1] : amountFormat.split('.')[0];

        },
        changePlanType(type){
            this.planTypeView = type;
        },
        async verifyTranslation(){
            let lang = Helper.convertSigla(Helper.getLangBrowser());

            //Temporario ate traduzir o polones
            lang = lang == 'pl' ? 'en' : lang;

            await this.getTranslation({
                sigla: lang,
                category: 'plans'
            });

            this.loadTranslation = true;
        }
    },
    components: {
        Loading
    }
}
</script>

<style lang="scss" scoped>
    @import '../scss/mixins';

    .loading{
        margin: auto;
    }
    .plans{
        font-family: Montserrat;

        @include media(sm,md){
            .content{
                margin: auto 30px;
            }
        }
    }

    .plans_login{
        background: rgba(217, 217, 217, 0.996);

        & .content{
            display: flex;
            justify-content: flex-end;
        }
    }
    .plans_login_item{
        border-left: 1px solid var(--black);
        font-size: 0.7em;
        padding: 8px 20px;
        font-weight: bold;

        &:hover:not(.plans_login_socialMedia){
            background: #d0d0d0;
        }

        &:first-child{
            border-left: none;
        }
    }
    .plans_login_socialMedia,
    .plans_footer_blockMidiaSocial_icons{
        & .icon{
            color: #d4d4d4;
            background: #858585;
            border-radius: 50%;
            margin-left: 5px;
            padding: 4px 5px;
            font-size: 0.9em;
        }
    }
    .plans_login_support{
        color: rgba(132, 132, 132, 0.996);

        &:hover{
            color: rgba(37, 96, 223, 0.996);;
        }
    }
    .plans_login_login{
        color: rgba(37, 96, 223, 0.996);
    }

    .plans_menu{
        background: rgba(230, 230, 230, 0.996);

        & .content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 22px 0px;
        }

        & .menuResponsive{
            display: none;
        }
        
        & .plans_menu_menuListResponsive{
            display: none;
        }

        @include media(sm,md){
            .plans_menu_actions a{
                display: none;
            }

            .plans_menu_actions .menuResponsive{
                display: block;
                font-size: 1.9em;
                color: var(--blue);
                cursor: pointer;
            }

            .plans_menu_menuListResponsive{
                display: flex;
                flex-direction: column;
                text-align: center;
                margin: auto 30px;
            }
        }
    }
    .plans_menu_logo{
        width: 160px;
    }
    .plans_menu_menuList{
        font-size: 0.9em;

        @include media(sm,md){
            display: none;
        }
    }
    .plans_menu_menuList_link{
        color: var(--black);
        padding: 15px 20px;
        font-weight: 600;

        &.active, &:hover{
            background: #FFFFFF73;
            border-radius: 10px;
        }
    }

    .plans_planType{
        margin: 50px auto;
        margin-top: 40px;
        margin-bottom: 0px;

        & .content{
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media(sm,md){
                flex-direction: column;
            }
        }

        @include media(sm,md){
            &.containerAdapter{
                width: auto;
            }
        }
    }
    .plans_planType_left{
        width: 40%;

        @include media(sm,md){
            width: auto;
            text-align: center;
        }
    }
    .plans_planType_left_title,
    .plans_toolsAndFeature_title,
    .plans_questions_title{
        font-size: 2.8em;
        font-weight: 600;
        line-height: 1.2;
        color: var(--black);

        & span{
            color: rgb(1, 212, 185);
        }

        @include media(sm,md){
            font-size: 2em;
        }
    }

    .plans_planType_right{
        width: 60%;

        @include media(sm,md){
            width: auto;
        }
    }
    .plans_planType_right_content{
        width: 405px;
        margin: auto;
        margin-left: 170px;

        @include media(sm,md){
            width: auto;
            margin-left: 0px;
        }
    }
    .plans_planType_right_desc{
        margin-bottom: 12px;
        font-size: 1.1em;

        @include media(sm,md){
            font-size: 0.9em;
            text-align: center;
            margin-top: 5px;
        }
    }
    .plans_planType_right_actions{
        display: flex;
        justify-content: center;

        & .btn2, & .btn3{
            padding: 11px 21px;
            margin: 0px;
            margin-right: 33px;
            border: 3px solid rgba(37, 96, 223, 0.996);
            font-weight: 600;

            &:last-child{
                margin-right: 0px;
            }
        }
    }
    .plans_planType_right_actions_action{
        cursor: pointer;

        @include media(sm,md){
            text-align: center;
        }
    }

    .plans_details{
        margin: 50px auto;
        margin-top: 0px;

        & .content{
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            @include media(sm,md){
                flex-direction: column;
            }
        }

        @include media(sm,md){
            &.containerAdapter{
                width: auto;
            }
        }
    }

    .plans_details_item{
        position: relative;
        width: 25%;
        margin-right: 20px;
        padding: 30px 15px;
        box-shadow: 0px 0px 10px rgba(37, 96, 223, 0.1);
        text-align: center;

        &:last-child{
            margin-right: 0px;
        }

        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3){
            & .plans_details_item_desc{
                margin-top: 35px;
            }
        }

        @include media(sm,md){
            width: auto;
            margin-bottom: 15px;
        }
    }
    .plans_details_item_tag{
        position: absolute;
        top: 35px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 0.65em;
        font-weight: 600;
        width: 120px;
        background: #f7f7f7;
        margin: auto;
        padding: 5px;
        border-radius: 5px;
        color: #2453b8fe;
    }
    .plans_details_item_title{
        font-size: 1.4em;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 41px;
    }
    .plans_details_item_subTitle{
        font-size: 0.7em;
        font-weight: 400;
        margin-top: -15px;
    }

    .plans_details_item_price{
        font-weight: 600;
    }
    .plans_details_item_price_amount{
        color: #263B5E;

        & strong{
            font-size: 3.4em;
            font-weight: 600;
        }
    }
    .plans_details_item_price_amountSmallsize{
        & strong{
            font-size: 1.8em;
        }

        & + .plans_details_item_price_desc{
            margin-top: 0px;
        }
    }
    .plans_details_item_price_amount_cents{
        margin-left: -1px;
    }
    .plans_details_item_price_desc{
        font-size: 0.7em;
        margin-top: -12px;
    }

    .plans_details_item_desc{
        font-size: 0.8em;
        margin: 17px auto;
        padding: 15px 0px;
        border-top: 1px solid var(--gray-500);
        border-bottom: 1px solid var(--gray-500);
    }

    .plans_details_item_desc_item_img{
        width: 12px;
    }

    .plans_details_item_action{
        display: inline-block;
        width: 100%;
    }

    .plans_details_item_details{
        font-size: 0.8em;
        margin-top: 25px;
    }
    .plans_details_item_details_title{
        color: #2453b8fe;
        font-weight: 600;
    }
    .plans_details_item_details_desc{
        font-size: 0.9em;
        font-weight: 400;
    }

    .plans_details_item_details_image{
        @include media(sm,md){
            width: 70%;
        }
    }

    .plans_security{
        background: rgba(245, 249, 252, 0.3);

        & .content{
            display: flex;
            justify-content: space-between;

            @include media(sm,md){
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }
    }
    .plans_security_item{
        width: 33%;
        font-size: 0.7em;
        display: flex;
        align-items: flex-start;

        @include media(sm,md){
            flex-direction: column;
            width: auto;
            margin-bottom: 15px;
        }
    }
    .plans_security_item_image{
        width: 55px;
        margin-right: 20px;

        @include media(sm,md){
            margin: auto;
        }
    }
    .plans_security_item_info_image{
        width: 100px;
    }
    .plans_security_item_info_text{
        font-weight: 600;
    }
    .plans_security_item_info_textSub{
        font-size: 0.9em;
    }

    .plans_toolsAndFeature{
        & .content{
            padding: 60px 50px;
        }

        @include media(sm,md){
            display: none;
        }
    }
    .plans_toolsAndFeature_title{
        text-align: center;
    }

    .plans_toolsAndFeature_table{
        width: 100%;
    }
    .plans_toolsAndFeature_table_header{
        background: #0060e3;
        color: var(--white);
        display: flex;
        padding: 10px 20px;
        border-radius: 5px;
        justify-content: space-between;
        margin-top: 30px;
    }
    .plans_toolsAndFeature_table_textStart{
        font-weight: 600;
    }
    .plans_toolsAndFeature_table_header_check{
        display: flex;
        align-items: center;
        width: 490px;
    }
    .plans_toolsAndFeature_table_header_check_text{
        font-size: 0.8em;
        font-weight: 500;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 3px solid var(--white);
    }

    .plans_toolsAndFeature_table_item_content{
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px 20px;
        background: #f4f9fc;
        border-radius: 5px;
        margin: 10px auto;
        cursor: pointer;
        flex-wrap: wrap;
        
        &.plans_toolsAndFeature_table_item_contentSub{
            cursor: default;
        }
    }
    .plans_toolsAndFeature_table_item_dropdown{
        position: absolute;
        background: #f3f9fc;
        box-shadow: 0px 0px 3px #b5b5b5;
        padding: 3px;
        left: -11px;
        border-radius: 50%;
    }
    .plans_toolsAndFeature_table_item_text{
        font-weight: 600;
    }
    .plans_toolsAndFeature_table_item_check{
        display: flex;
        width: 490px;
        justify-content: space-around;
    }
    .plans_toolsAndFeature_table_item_check_check{
        width: 25%;
        text-align: center;

        & img{
            width: 20px;
        }
    }

    .plans_questions{
        & .content{
            padding: 60px 50px;

            @include media(sm,md){
                padding: 0px;
            }
        }
    }

    .plans_questions_container{
        display: flex;
        justify-content: flex-start;

        @include media(sm,md){
            flex-direction: column;
        }
    }
    .plans_questions_title{
        width: 300px;

        @include media(sm,md){
            width: auto;
            text-align: center;
        }
    }
    .plans_questions_questions{
        width: 100%;
        margin-left: 40px;

        @include media(sm,md){
            margin-left: 0px;
        }
    }

    .plans_questions_questions_question_text{
        font-weight: 600;
        color: var(--black);
        cursor: pointer;
        background: #fafcfd;
        padding: 20px;
    }
    .plans_questions_questions_question_reply{
        padding: 20px;
    }

    .plans_footer{
        background: #f2f2f2;
        color: #000;

        & .content{
            display: flex;
            justify-content: space-between;
            padding: 80px 0px;

            @include media(sm,md){
                flex-direction: column;
            }
        }
    }
    .plans_footer_block{
        margin-right: 10px;

        &:last-child{
            margin-right: 0px;
        }

        @include media(sm,md){
            border-bottom: 1px solid var(--gray-500);
            margin-bottom: 15px;
            padding-bottom: 15px;
            text-align: center;
        }
    }

    .plans_footer_blockAbout{
        font-size: 0.8em;
        min-width: 220px;
        flex-basis: 220px;

        @include media(sm,md){
            flex-basis: auto;
        }
    }
    .plans_footer_blockAbout_title{
        font-weight: 600;
    }

    .plans_footer_blockMenu1,
    .plans_footer_blockMenu2,
    .plans_footer_blockMenu3{
        text-transform: uppercase;
        font-size: 0.82em;

        & li a {
            display: inline-block;
            color: var(--black);
            width: 100%;
            padding: 10px 20px;
            font-weight: 600;

            &:hover{
                background: #fafafa;
            }
        }
    }
    .plans_footer_blockMenu1{
        width: 410px;

        @include media(sm,md){
            width: auto;
        }
    }
    .plans_footer_blockMenu2{
        width: 410px;

        @include media(sm,md){
            width: auto;
        }
    }
    .plans_footer_blockMenu3{
        width: 400px;

        @include media(sm,md){
            width: auto;
        }
    }

    .plans_footer_blockMidiaSocial{
        display: flex;
        font-size: 0.8em;
        width: 510px;

        @include media(sm,md){
            width: auto;
            flex-direction: column;
        }
    }
    .plans_footer_blockMidiaSocial_icons{
        display: flex;
        flex-direction: column;
        font-size: 1.1em;
        margin-right: 7px;

        & .icon{
            background: #17191e;
            padding: 6px;
            margin-top: 3px;

            &:first-child{
                margin-top: 0px;
            }
        }

        @include media(sm,md){
            align-items: center;
            justify-content: center;
            flex-direction: row;
        }
    }
</style>