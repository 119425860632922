import Sha1 from 'js-sha1';
import router from '../router';
import Helper from '../assets/js/helper';

export default {
    namespaced: true,
    state: {},
    getters: {
        isUserImpact(){
            return router.currentRoute.query['irclickid'] ? true : false;
        },
    },
    mutations: {
        identify(){
            let data = {
                customerId: this.state.user.user_identifier,
                customerEmail: Sha1(this.state.user.email)
            };

            console.log(data);
            ire('identify', data);
        },

        lead(state, {id, email}){
             let data = {
                orderId: id,
                customerId: id,
                customerEmail: Sha1(email)
            };

            console.log(data);
            ire('trackConversion', 41661, data , {
                verifySiteDefinitionMatch: true
            }
            );
        },

        sale(){
            if(
                this.getters.product.isThirdParty ||
                !this.state.isPlan ||
                this.state.userFather.id != Helper.builderallAccounts.impact_2494737
            ) return;

            let data = {
                orderId: sessionStorage.getItem('idInvoice'),
                customerId: this.state.user.user_identifier,
                customerEmail: Sha1(this.state.user.email),
                currencyCode: this.getters.product.currency,
                orderPromoCode: '',
                orderDiscount: '',
                items: [
                    {
                        subTotal: !this.getters.product.isTrialDays ? this.getters.product.amount : 0.00,
                        category: !this.getters.product.isTrialDays ? "Plans" : "Plans Trial",
                        sku: this.getters.product.id,
                        quantity: 1,
                        name: this.getters.product.title
                    }
                ]
            };

            //data.customerStatus = "Customer Status here",

            if(this.getters.hasCouponAndIsValid || sessionStorage.getItem('coupon_impact_paypal')){
                data.orderPromoCode = sessionStorage.getItem('coupon_impact_orderPromoCode') || this.state.coupon.code;
                data.orderDiscount = sessionStorage.getItem('coupon_impact_orderDiscount') || this.getters.product.amountCouponDiscount;

                sessionStorage.removeItem('coupon_impact_paypal');
                sessionStorage.removeItem('coupon_impact_orderPromoCode');
                sessionStorage.removeItem('coupon_impact_orderDiscount');
            }

            console.log(data);
            ire('trackConversion', 41662, data, {
                verifySiteDefinitionMatch: true
            });
        }
    },
    actions: {}
}