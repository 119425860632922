import Helper from "./helper";

const SLUGS_PLANS = {
    promotionalPremiumUsd9: 'promotional-premium-usd-9', //'https://checkout.builderall.com/payment/promotional-premium-usd-9',
    promotionalPremiumEur9: 'promotional-premium-eur-9', //'https://checkout.builderall.com/payment/promotional-premium-eur-9', 
    promotionalPremiumBrl19: 'promotional-premium-brl-19', //'https://checkout.builderall.com/payment/promotional-premium-brl-19', 
    
    
    premiumBuilderallUsd69: 'premium-builderall-usd-69', //'https://checkout.builderall.com/payment/premium-builderall-usd-69', 
    premiumBuilderallEur59: 'premium-builderall-eur-59', //'https://checkout.builderall.com/payment/premium-builderall-eur-59', 
    premiumBuilderallBrl149: 'premium-builderall-brl-149', //'https://checkout.builderall.com/payment/premium-builderall-brl-149', 

    premiumTrialUsd69: 'premium-trial-usd-69',
    premiumTrialEur59: 'premium-trial-eur-59',
    premiumTrialBrl149: 'premium-trial-brl-149',
    premiumTrial30Brl: 'premium-trial-30-brl', 
    premiumTrial30Usd: 'premium-trial-30-usd', 
    premiumTrial30Eur: 'premium-trial-30-eur',

    nicheSpecialBrl: 'niche-special-brl',
    nicheSpecialUsd: 'niche-special-usd',
    nicheSpecialEur: 'niche-special-eur',

    testBrl: 'test-brl',
    testUsd: 'test-usd',
    testEur: 'test-eur',

    starterPlan: 'starter-plan',
    marketerPlan: 'marketer-plan',
    premiumPlan: 'premium-plan',
    funnelClubPlan: 'funnel-club-plan',

    wpStarterPlan: 'wp-starter-plan',
    wpMarketerPlan: 'wp-marketer-plan',
    wpPremiumPlan: 'wp-premium-plan',
    wpFunnelClubPlan: 'wp-funnel-club-plan',
    wpcheetahBasicUsd: 'wpcheetah-basic-usd',
    wpMarketerUsd: 'wp-marketer-usd',
    wpPremiumBuilderallUsd: 'wp-premium-builderall-usd',
    wpFunnelClubUsd: 'wp-funnel-club-usd',

    onlyCheetahUsd: 'only-cheetah-usd',
    marketerSpecialUsd: 'marketer-special-usd',
    funnelClubUsd992021: 'funnel-club-usd-99-2021',

    advancedPlanTrial: 'advanced-plan-trial'
};
const CURRENCY_CODE = {
    BRL: 'BRL',
    USD: 'USD',
    EUR: 'EUR'
};
let slugRedir = null;
let isNewPage = false;

/**
 * 
 * @param slugPlanAccess
 * @param currencyCodeUser
 * 
 * @retrurn retorna o nome do estilo do plano: PROMOTIONAL | PREMIUMTRIAL
 */
export const verifyAccessUserPlan = (slugPlanAccess, currencyCodeUser) => {

    /**
     * Usuario que acessou o plano promotional e o UPLine não é a conta 10 ou 30877.
     */
    if([SLUGS_PLANS.promotionalPremiumUsd9,SLUGS_PLANS.promotionalPremiumEur9,SLUGS_PLANS.promotionalPremiumBrl19].includes(slugPlanAccess)){
        if(currencyCodeUser == CURRENCY_CODE.USD) slugRedir = SLUGS_PLANS.premiumBuilderallUsd69;
        if(currencyCodeUser == CURRENCY_CODE.EUR) slugRedir = SLUGS_PLANS.premiumBuilderallEur59;
        if(currencyCodeUser == CURRENCY_CODE.BRL) slugRedir = SLUGS_PLANS.premiumBuilderallBrl149;

        return 'PROMOTIONAL';
    }

    /**
     * Usuario que acessou o plano de nicho
     */
    if([SLUGS_PLANS.nicheSpecialBrl,SLUGS_PLANS.nicheSpecialEur,SLUGS_PLANS.nicheSpecialUsd].includes(slugPlanAccess)){
        if(currencyCodeUser == CURRENCY_CODE.USD) slugRedir = SLUGS_PLANS.premiumBuilderallUsd69;
        if(currencyCodeUser == CURRENCY_CODE.EUR) slugRedir = SLUGS_PLANS.premiumBuilderallEur59;
        if(currencyCodeUser == CURRENCY_CODE.BRL) slugRedir = SLUGS_PLANS.premiumBuilderallBrl149;

        return 'NICHE';
    }


    if([SLUGS_PLANS.premiumTrialUsd69,SLUGS_PLANS.premiumTrialEur59,SLUGS_PLANS.premiumTrialBrl149,SLUGS_PLANS.premiumTrial30Brl,SLUGS_PLANS.premiumTrial30Eur,SLUGS_PLANS.premiumTrial30Usd].includes(slugPlanAccess)){
        if(currencyCodeUser == CURRENCY_CODE.USD) slugRedir = SLUGS_PLANS.premiumBuilderallUsd69;
        if(currencyCodeUser == CURRENCY_CODE.EUR) slugRedir = SLUGS_PLANS.premiumBuilderallEur59;
        if(currencyCodeUser == CURRENCY_CODE.BRL) slugRedir = SLUGS_PLANS.premiumBuilderallBrl149;

        return 'PREMIUMTRIAL';
    }

    if([SLUGS_PLANS.testUsd,SLUGS_PLANS.testEur,SLUGS_PLANS.testBrl].includes(slugPlanAccess)){
        if(currencyCodeUser == CURRENCY_CODE.USD) slugRedir = SLUGS_PLANS.premiumTrialUsd69;
        if(currencyCodeUser == CURRENCY_CODE.EUR) slugRedir = SLUGS_PLANS.premiumTrialEur59;
        if(currencyCodeUser == CURRENCY_CODE.BRL) slugRedir = SLUGS_PLANS.premiumTrialBrl149;

        return 'PREMIUMTRIAL';
    }

    if([SLUGS_PLANS.advancedPlanTrial].includes(slugPlanAccess)){
        isNewPage = true;
        slugRedir = '/plans';
        return 'PREMIUMTRIAL';
    }

    if([SLUGS_PLANS.starterPlan,SLUGS_PLANS.marketerPlan,SLUGS_PLANS.premiumPlan, SLUGS_PLANS.funnelClubPlan,
        SLUGS_PLANS.wpStarterPlan, SLUGS_PLANS.wpMarketerPlan, SLUGS_PLANS.wpPremiumPlan, SLUGS_PLANS.wpFunnelClubPlan].includes(slugPlanAccess)){

        if(SLUGS_PLANS.starterPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.onlyCheetahUsd;
        if(SLUGS_PLANS.marketerPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.marketerSpecialUsd;
        if(SLUGS_PLANS.premiumPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.premiumBuilderallUsd69;
        if(SLUGS_PLANS.funnelClubPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.funnelClubUsd992021;

        if(SLUGS_PLANS.wpStarterPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.wpcheetahBasicUsd;
        if(SLUGS_PLANS.wpMarketerPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.wpMarketerUsd;
        if(SLUGS_PLANS.wpPremiumPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.wpPremiumBuilderallUsd;
        if(SLUGS_PLANS.wpFunnelClubPlan == slugPlanAccess) slugRedir = SLUGS_PLANS.wpFunnelClubUsd;

        return 'COUNTRY_PRICE'
    }
};

export const isRedirUser = () => slugRedir != null ? true : false;

export const getUrlRedir = () => `${Helper.getEnvs().env == 'dev' ? 'http://' : 'https://'}${Helper.getEnvs().urlCheckoutFront}${isNewPage ? slugRedir : `/payment/${slugRedir}`}`;

export default { 
    verifyAccessUserPlan,
    isRedirUser,
    getUrlRedir
};