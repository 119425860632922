<!--
<Resources
        name="Recursos"
        :headers="['Builderall Builder', 'Builderall Premium']"
        :resources="[
    {
        name: 'Construtor de Sites',
        checks: [true, false]
    },
    {
        name: 'Construtor de Sites para Wordpress',
        checks: [false, false]
    },
    {
        name: 'MailingBoss',
        checks: [false, false]
    },
    {
        name: 'Builderall Ecommerce',
        checks: [false, false]
    }
]"
/>
-->
<template>
    <div class="resources">
        <div class="resources_header">
            <div class="resources_header_name">{{ name }}</div>

            <div class="resources_header_items">
                <p class="resources_header_items_item" v-for="header in headers">{{ header }}</p>
            </div>
        </div>

        <div class="resources_resourceItems">
            <div class="resources_resourceItems_item" v-for="resource in resources">
                <p class="resources_resourceItems_item_name"><span v-html="resource.name"></span></p>

                <div class="resources_resourceItems_item_checks">
                    <span class="resources_resourceItems_item_checks_item" v-for="check in resource.checks">
                        <img v-if="check" :src="require('../../assets/imagem/verify-2.svg')">

                        <span v-else><i class="icon icon-x"></i></span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Resources",
        props: {
            name: String,
            headers: Array,
            resources: Object
        }
    }
</script>

<style lang="scss" scoped>
    .resources{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;

        --border-grid: 2px solid #87b3f2;
        --width-name: 450px;
        --width-item: 680px;
        --padding-item: 0px 40px;
    }

    .resources_header{
        position: sticky;
        top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #0060e4;
        color: var(--white);
        border-radius: 5px 5px 0px 0px;
        padding: 17px 40px;
        border: var(--border-grid);
        border-bottom: none;
    }
    .resources_header_name{
        width: var(--width-name);
        font-size: 1.6em;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }

    .resources_header_items{
        display: flex;
        width: var(--width-item);
        justify-content: space-between;
    }
    .resources_header_items_item{
        width: 222px;
        border-right: 2px solid var(--white);
        text-align: center;
        margin-right: -43px;

        &:last-child{
            border: none;
        }
    }

    .resources_resourceItems{
        display: flex;
        flex-direction: column;
    }
    .resources_resourceItems_item{
        display: flex;
        justify-content: space-between;
        border-bottom: var(--border-grid);
        border-left: var(--border-grid);
        border-right: var(--border-grid);
    }
    .resources_resourceItems_item_name{
        width: var(--width-name);
        padding: 14px 40px;
        padding-right: 0px;
        font-weight: 600;
    }
    .resources_resourceItems_item_checks{
        display: flex;
        justify-content: space-between;
        width: var(--width-item);
    }
    .resources_resourceItems_item_checks_item{
        width: 50%;
        border-left: var(--border-grid);
        padding: 14px 40px;
        text-align: center;

        & img{
            width: 18px;
        }

        & .icon{
            color: #c4d9f8;
            font-size: 1.4em;
        }
    }
</style>