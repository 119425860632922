<template>
    <div class="unavailable">
        <div class="row">
            <div class="col-lg-24">
                <div class="unavailable">
                    <span class="unavailable_icon material-icons">sentiment_dissatisfied</span>
                    <p>{{ message }}</p>

                    <a v-if="actionHref" :href="actionHref">
                        <at-button type="primary" size="">
                            <span>{{ actionName }}</span>
                        </at-button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Unavailable",
    props: ['message', 'actionName', 'actionHref'],
    computed: {
        ...mapGetters([
            'translateWord'
        ])
    }
}
</script>

<style lang="scss" scoped>
    .unavailable{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 600px;
        text-align: center;
        margin: auto;
        
        & > *{
            margin-bottom: 20px;
        }
    }
    
    .unavailable_icon{
        font-size: 7em;
    }
</style>