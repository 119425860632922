<template>
    <div class="loading">
        <!-- <span class="icon icon-loader loading_icon"></span> -->
        <img class="loading_img" src="../assets/imagem/loading.svg" />
        <p class="loading_title" v-html="title"></p>
    </div>
</template>

<script>
    export default {
        name: "Loading",
        props: ['title']
    }
</script>