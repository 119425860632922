<template>
    <div class="paymentAdmin" v-if="viewAdmin">
        <h1 class="paymentAdmin_title">Informações de pagamento</h1>
        <p class="paymentAdmin_title_sub">* No momento somente é feito buscas do pagarme (BRL)</p>

        <div class="paymentAdmin_container">
            <div class="paymentAdmin_search">
                <at-input class="input" v-model="idUser" placeholder="ID do usuario"></at-input>

                <at-select v-model="methodPayment">
                    <at-option-group label="Metodo de Pagamento">
                        <at-option value="credit_card">Cartão de Credito</at-option>
                        <at-option value="boleto">Boleto</at-option>
                    </at-option-group>
                </at-select>

                <at-select v-model="typePayment">
                    <at-option-group label="Tipo do pagamento">
                        <at-option value="failed">Falhas</at-option>
                        <at-option value="success">Sucesso</at-option>
                        <at-option value="all">Todos os tipos</at-option>
                    </at-option-group>
                </at-select>

                <at-button type="primary" @click.native="searchDetailsPayments">BUSCAR</at-button>
            </div>

            <div class="paymentAdmin_detailsPayments">
                <div class="row paymentAdmin_detailsPayments_itens" v-for="(payment, index) in payments" :key="index">
                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>ID Pagamento - Builder Gateway</strong></p>
                        <p>{{ payment.id_pagarme_payment }}</p>
                    </div>

                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>Tipo da transação</strong></p>
                        <p>{{ payment.type }}</p>
                    </div>

                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>Mensagem</strong></p>
                        <p>{{ payment.message }}</p>
                    </div>

                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>ID Pagamento - Gateway</strong></p>
                        <p>{{ payment.charge }} <i class="icon icon-copy" @click="copy(payment.charge)"></i></p>
                    </div>

                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>6 Primeiros Digitos do cartão</strong></p>
                        <p>{{ payment.first_six_digits }}</p>
                    </div>

                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>4 Ultimos Digitos do cartão</strong></p>
                        <p>{{ payment.last_four_digit }}</p>
                    </div>

                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>Bandeira cartão</strong></p>
                        <p>{{ payment.brand }}</p>
                    </div>

                    <div class="paymentAdmin_detailsPayments_itens_item col-12">
                        <p><strong>Data de criação</strong></p>
                        <p>{{ payment.created_at }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import Helper from '../assets/js/helper'

export default {
    name: "PaymentAdmin",
    data(){
        return {
            idUser: '',
            methodPayment: 'credit_card',
            typePayment: 'failed',
            payments: [],
            viewAdmin: false
        }
    },
    async created(){
        if(Helper.getEnvs().env == 'dev'){
            this.viewAdmin = true;
            return;
        }

        let pass = 'bg2022';

        let response = await prompt("Senha de acesso");

        if(response == pass) this.viewAdmin = true;
    },
    methods: {
        async searchDetailsPayments(){
            this.payments = [];

            this.$Notify({
                title: 'Buscando pagamentos'
            });

            let payments = (await this.$http.post('/info/payments', {
                application: 'OFFICE',
                id_user: this.idUser,
                business_gateway: 'pagarme',
                method_payment: this.methodPayment,
                type_payment: this.typePayment
            })).data;

            if(!payments.hasOwnProperty('gateway') || !payments.gateway){

                this.$Notify({
                    title: 'Não veio nenhum retorno de pagamentos',
                    type: 'error'
                });

                return false;
            }

            this.$Notify({
                title: 'Pagamento encontrados com sucesso',
                type: 'success'
            });

            this.payments = payments.gateway;
        },
        async copy(text){
            (await this.$copyText(text));

            this.$Notify({
                title: `${text}, copiado com sucesso`,
                type: 'success'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    // Small devices
    @mixin sm {
        @media screen and(min-width: 320px) and (max-width: 480px) {
            @content;
        }
    }

    // Medium devices
    @mixin md {
        @media screen and(min-width: 480px) and (max-width: 1000px) {
            @content;
        }
    }

    // Large devices
    /*@mixin lg {
        @media screen and(min-width: 640px) and (max-width: 768px){
            @content;
        }
    }*/

    // Extra large devices
    @mixin xl {
        @media screen and(min-width: 1000px) and (max-width: 1280px) {
            @content;
        }
    }

    .paymentAdmin_title{
        text-align: center;
    }
    .paymentAdmin_title_sub{
        font-size: 0.8em;
        text-align: center;
    }

    .paymentAdmin_container{
        display: flex;
        flex-direction: column;
        max-width: 800px;
        margin: auto;
        margin-top: 50px;

        @include sm{
            padding: 0px 15px;
        }
    }

    .paymentAdmin_search{
        display: flex;
        align-items: stretch;
        width: 100%;

        & > * {
            margin-right: 8px;
        }

        & > *:last-child{
            margin-right: 0px;
        }

        & .at-input{
            width: 100%;
            margin-bottom: 0px;
        }

        @include sm{
            flex-direction: column;

            & > * {
                margin: 0px;
                margin-bottom: 5px !important;
            }
        }
    }

    .paymentAdmin_detailsPayments_itens{
        padding: 15px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 3px 5px 6px #c8c8c8;
        margin-bottom: 15px;
    }
    .paymentAdmin_detailsPayments_itens_item{
        text-align: center;
        padding: 15px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        margin-bottom: 5px;

        @include sm{
            flex-basis: 100%;
            max-width: 100%;
        }

        & .icon{
            margin-left: 5px;
            cursor: pointer;
        }
    }
</style>