<template>
    <div v-if="isLoading" class="app_loading">
        <Loading v-if="isLoading"/>
    </div>

    <div v-else class="row">
        <div class="col-lg-24">
            <div class="planOrFeeInactive">
                <span class="planOrFeeInactive_icon material-icons">sentiment_dissatisfied</span>

                <p>
                    {{ translateWord('Infelizmente a promoção do plano {0} acabou. Mas não se preocupe. Clicando no botão abaixo voce sera redirecionado para o nosso plano premium', [
                        this.planCurrent
                    ]) }}
                </p>

                <a :href="linkNextPlan">
                    <at-button type="primary" size="">
                        <span>{{ translateWord('IR PARA PLANO') }}</span>
                    </at-button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '../components/Loading';

import { mapState, mapGetters } from 'vuex';

export default {
    name: "PlanOrFeeInactive",
    data(){
        return {
            isLoading: true,
            planCurrent: '',
            linkNextPlan: '',

        }
    },
    computed: {
        ...mapState([
            'endpointOffice'
        ]),
        ...mapGetters([
            'translateWord',
            'getSlug'
        ])
    },
    async created(){
        let planOrFee = (await this.$httpOffice.get(`${this.endpointOffice.PEGAR_PLANO_OU_TAXA}/${this.getSlug}`)).data.data;

        this.planCurrent = planOrFee.titulo || planOrFee.name || null;
        this.linkNextPlan = planOrFee.redir_on_inactive;

        //@TODO Logica para o lançamento do 5.0
        if(this.linkNextPlan == ''){
            switch(planOrFee.moeda){
                case 'BRL': this.linkNextPlan = `https://checkout.builderall.com/payment/premium-builderall-brl-149`; break;
                case 'USD': this.linkNextPlan = `https://checkout.builderall.com/payment/premium-builderall-usd-69`; break;
                case 'EUR': this.linkNextPlan = `https://checkout.builderall.com/payment/premium-builderall-eur-59`; break;
            }
        }

        this.isLoading = false;
    },
    components: { Loading }
}
</script>

<style lang="scss" scoped>
    .planOrFeeInactive{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 600px;
        text-align: center;
        margin: auto;

        & > * {
            margin-bottom: 20px;
        }
    }

    .planOrFeeInactive_icon{
        font-size: 7em;
    }
</style>