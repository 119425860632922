<template>
    <div class="banner banner_blackfriday">
        <img :src="imageBanner" alt="BlackFriday" title="BlackFriday">
    </div>
</template>

<script>
import Helper from '../assets/js/helper';

export default {
    name: "Banner",
    props: [
        'banners'
    ],
    data(){
        return {}
    },
    created(){

    },
    computed: {
        imageBanner(){
            let linkBanner = '';

            switch(Helper.getLangBrowser()){
                case 'en':
                    linkBanner = this.banners.en[!Helper.isMobile() ? 'desktop' : 'mobile'];
                    break;

                case 'pt':
                    linkBanner = this.banners.pt[!Helper.isMobile() ? 'desktop' : 'mobile'];
                    break;

                case 'fr':
                    linkBanner = this.banners.fr[!Helper.isMobile() ? 'desktop' : 'mobile'];
                    break;

                case 'de':
                    linkBanner = this.banners.de[!Helper.isMobile() ? 'desktop' : 'mobile'];
                    break;

                case 'it':
                    linkBanner = this.banners.it[!Helper.isMobile() ? 'desktop' : 'mobile'];
                    break;

                case 'es':
                    linkBanner = this.banners.es[!Helper.isMobile() ? 'desktop' : 'mobile'];
                    break;

                default:
                    linkBanner = this.banners.en[!Helper.isMobile() ? 'desktop' : 'mobile'];
                break;
            }

            return linkBanner;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../scss/mixins';

    .banner{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .banner_blackfriday{
        & img{
            width: 1921px;
            max-width: none;
        }

        @include media(sm,md){
            img{
                max-width: 100%;
            }
        }
    }
</style>